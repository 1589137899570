.FooterNavButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
}
.FooterNavButton:active {
  .FooterNavButtonIcon {
    path {
      stroke: var(--Light-Mode-Primary, #fd0);
    }
  }
  .FooterNavButtonTitle {
    h6 {
      color: var(--Light-Mode-Primary, #fd0);
    }
  }
}
