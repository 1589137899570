.CallButton {
  border-radius: 20px;
  padding: 12px 16px;
  background: var(--color-mini-button-reverse);
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;

  &.isDisabled {
    cursor: not-allowed;
  }

  svg {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  h6 {
    color: var(--color-text-white);
    margin-bottom: 4px;
  }

  .h8 {
    color: var(--color-inverse-25);
    font-weight: 400;
  }
}
