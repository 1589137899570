#WelcomeClient {
  width: 100vw;
  height: 100dvh;
  background-color: var(--color-light-mode-black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  pointer-events: none;
}
.WelcomeClientContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.WelcomeClientContentTextContainer {
  height: 29px;
  width: 154px;
  position: relative;
  overflow: hidden;
  animation: flash 500ms ease infinite alternate;
  animation-delay: 2s;
}

.WelcomeClientContentLogo {
  animation: logoAnimation 2s forwards;
  width: 106px;
  height: 70px;
}

.WelcomeClientContentText {
  animation: textAnimation 2s forwards;
  position: absolute;
  top: -100%;
}

@media (min-width: 1000px) {
  #WelcomeClient {
    width: 500px;
    height: 700px;
  }
}

.WelcomeClientContentLogoContainer {
  animation: bounce 2s ease infinite;
  animation-delay: 2s;
  transform-origin: bottom;
}

@keyframes textAnimation {
  50% {
    top: -100%;
  }
  90% {
    top: 0;
  }
  100% {
    top: 0;
  }
}

@keyframes logoAnimation {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  50% {
    margin-bottom: 0;
  }
  90% {
    margin-bottom: 16px;
  }
  100% {
    margin-bottom: 16px;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  70%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(5px);
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
