.GuestAuthForgetPasswordHeader {
  display: flex;
  align-items: center;
  min-height: 44px;

  h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

.ForgetPasswordStepper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  position: relative;

  .ForgetPasswordStepperWrapper {
    padding-top: 21px;
    overflow: hidden;
    display: flex;
    transition: margin-left 0.3s ease-in-out;
    justify-content: space-between;

    .PrimaryButton {
      position: absolute;
      width: 100%;
    }

    > div {
      width: calc(100% / 3);
    }
  }
  .StepperProgressBarLine {
    width: 78px;
    height: 3px;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    margin-top: 16px;
    margin-bottom: 40px;
    position: relative;

    &.isPassed {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--color-success);
        width: 100%;
        height: 100%;
        border-radius: 8px;
        animation: progress 0.3s ease-in-out;
      }
    }
  }
}
