@import "src/assets/styles/_variables";

.PrimaryButton {
  border-radius: 12px;
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  h5 {
    transition: $defaultTransition;
    text-wrap: nowrap;
  }

  &.isLoading {
    pointer-events: none;
    height: 52px;
  }

  &.typeA {
    background-color: var(--color-admin-primary);
    &.isDisabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: var(--color-admin-primary-hover);
    }
  }

  &.typeB {
    background-color: var(--color-mini-button);

    &:hover {
      background-color: var(--color-group-background-light-mode);

      h5 {
        color: var(--color-text-black);
      }
    }
  }

  &.typeC {
    background-color: var(--color-red-15);

    h5 {
      color: var(--color-error);
    }

    &:hover {
      background-color: var(--color-error);

      h5 {
        color: var(--color-text-white);
      }
    }
  }

  &.typeE {
    padding: 13px;
    background-color: var(--color-admin-primary);

    h6 {
      line-height: 20px;
      white-space: nowrap;
    }
  }

  &.typeD {
    background-color: var(--color-mini-button);

    &:hover {
      background-color: var(--color-group-background-light-mode);
    }

    &.isDisabled {
      h5 {
        color: var(--color-inverse-50);
      }
      cursor: not-allowed;
      img{
        filter: grayscale(100%) opacity(0.5);
      }
      &:hover {
        background-color: var(--color-mini-button);
      }

    }
  }

  &.typeF {
    padding: 15px;
    background: var(--color-gradient-main);
  }

  &.typeH {
    padding: 15px;
    background-color: var(--color-admin-primary-disabled);
  }

  &.typeI {
    background: var(--color-preferred-secondary-bg);
    &.isDisabled {
      cursor: not-allowed;
      background-color: var(--color-mini-button);
      scale: none;
      h5 {
        color: var(--color-inverse-50);
      }
    }

  }
  &.typeS {
    background: var(--color-text-white);
    border-radius: 78px;
    border: 2px solid var(--color-primary);


  }
  &.typeP {
    background: var(--color-primary);
    border-radius: 78px;
    position: fixed;
    bottom:30px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    &:active{
      scale: 0.95;
    }
    &.isDisabled{
      cursor: not-allowed;
      background-color: var(--color-mini-button);
      scale: none;
    }

  }
  &.typeM {
    background: var(--color-primary);
    border-radius: 78px;
    width: 138px;
    padding: 10px;
    margin-top: 20px;
    &:active{
      scale: 0.95;
    }

  }
  &.typeR {
    background: var(--color-error);
    .PrimaryButtonText{
    color: var(--color-text-white);
    }
  }
  &.isDisabled{
    cursor: not-allowed;
    background-color: var(--color-inverse-75);
    scale: none;
    .PrimaryButtonText{
      color: var(--color-inverse-25);
    }
  }
}

.PrimaryButtonIcon {
  margin-right: 24px;
}
