@import "src/assets/styles/_variables";

.SmallButton {
  background: none;
  border-bottom: 1px solid transparent;

  h6,
  p {
    color: var(--color-secondary);
    transition: $defaultTransition;
  }

  .Underlined {
    color: var(--color-primary);
    text-decoration-line: underline;
  }

  &:active {
    h6,
    p {
      color: var(--color-secondary);
    }

    border-bottom: 1px solid var(--color-secondary);
  }
}

@media (max-width: $mobile) {
  .SmallIconButton {
    width: 34px;
    height: 34px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
