@import "src/assets/styles/mixins";
.AdminMapViewTable {
  background-color: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  &.isCircle {
    border-radius: 50%;
    .AdminMapViewTableActions{
      .AdminMapViewTableAction{
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          right: 0;
          top: 0;
        }
        &:nth-child(3) {
          left: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .AdminMapViewTableName {
    color: var(--color-inverse-100);
    @include text-truncate-ellipsis(1);
    word-break: break-word;
    text-align: center;
  }

  .AdminMapViewTableActions {
    .AdminMapViewTableAction {
      width: 30px;
      height: 30px;
      border: 2px solid var(--color-text-white);
      position: absolute;
      background: var(--color-inverse-25);
      border-radius: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      &.isActive {
        //background: linear-gradient(148deg, #5F5FF6 19.23%, #5C2BC4 80.99%);
        //background: var(--color-admin-primary);
        background: var(--color-secondary);
      }
      svg {
        fill: var(--color-text-white);
        //color: var(--color-text-black);
        //width: 75%;
        //height: 75%;
        width: 20px;
        height: 20px;
        path {
          fill: var(--color-text-white);
          //color: var(--color-text-black);
        }
      }
      h6 {
        color: var(--color-text-white);
        //color: var(--color-text-black);
      }

      &:nth-child(1) {
        left: -10px;
        top: -10px;
      }
      &:nth-child(2) {
        right: -10px;
        top: -10px;
      }
      &:nth-child(3) {
        left: -10px;
        bottom: -10px;
      }
      &:nth-child(4) {
        right: -10px;
        bottom: -10px;
      }
    }
  }

  &.orderInTable {
    background: linear-gradient(148deg, #5f5ff6 19.23%, #5c2bc4 80.99%);
    .AdminMapViewTableName {
      color: var(--color-text-white);
    }
  }
  &.isAnimated {
    animation-name: pulse;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    background: var(--color-secondary);
  }
}

//@keyframes pulse1{
//  0%{
//    box-shadow: none;
//  }
//  60%{
//    box-shadow: 0 0 0 4px #7a52ca;
//  }
//  100%{
//    box-shadow: none;
//  }
//}

//@keyframes pulse2{
//  0%{
//    box-shadow: none;
//  }
//  30%{
//    box-shadow: 0 0 0 4px #7a52ca;
//  }
//  60%{
//    box-shadow: 0 0 0 4px #7a52ca,0 0 0 8px #B592FF;
//  }
//  100%{
//    box-shadow: none;
//  }
//}

//@keyframes pulse3{
//  0%{
//    box-shadow: none;
//  }
//  20%{
//    box-shadow: 0 0 0 4px #7a52ca;
//  }
//  40%{
//    box-shadow: 0 0 0 4px #7a52ca,0 0 0 8px #B592FF;
//  }
//  60%{
//    box-shadow: 0 0 0 4px #7a52ca,0 0 0 8px #B592FF,0 0 0 12px #DCCBFF;
//  }
//  100%{
//    box-shadow: none;
//  }
//}

@keyframes pulse {
  0% {
    box-shadow: none;
  }
  20% {
    box-shadow: 0 0 0 4px #ffb46d;
  }
  40% {
    box-shadow: 0 0 0 4px #ffb46d, 0 0 0 8px #ffcfa2;
  }
  60% {
    box-shadow: 0 0 0 4px #ffb46d, 0 0 0 8px #ffcfa2, 0 0 0 12px #ffe7d0;
  }
  100% {
    box-shadow: none;
  }
}
