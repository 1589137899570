
.MicrophoneHintModalHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .CloseButton{
    width: 24px;
    height: 24px;
  }
  .MicrophoneHintModalTitle{
    color: var(--color-text-white);
  }
}
.MicrophoneHintModalBody{
  img{
    width: 100%;
    height: 100%;
  }

}
.MicrophoneHintModalFooter{
  h5{
    color: var(--color-text-white);
  }

}