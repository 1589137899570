@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.AllBusinessesHamburgerMenu {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px 16px 0 16px;
  background-color: var(--color-text-black);
  z-index: 100;
}

.AllBusinessesHamburgerMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h3,
  p {
    color: var(--color-text-white);
  }

  .AllBusinessesHamburgerMenuLeftArea {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .AllBusinessesHamburgerMenuGuestWrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--color-light-gray);
    cursor: pointer;
    margin-right: 8px;
  }

  .AllBusinessesHamburgerMenuGuestEditButton {
    background-color: var(--color-primary);
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-text-black);
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .AllBusinessesHamburgerMenuGuestImageContainer {
    width: 56px;
    height: 56px;

    .GuestProfileWithIconAvatar {
      height: 45px;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .AllBusinessesHamburgerMenuGuestName {
    margin-right: 8px;
    @include text-truncate-ellipsis(1);
    white-space: nowrap;
    word-break: break-all;
  }

  .AllBusinessesHamburgerMenuGuestDetailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    h4 {
      color: var(--color-text-white);
    }

    .AllBusinessesHamburgerMenuGuestZoneAndTable {
      color: var(--color-inverse-25);
      display: flex;
      align-items: center;
      width: 100%;

      h5 {
        color: var(--color-inverse-25);
      }

      h6 {
        color: var(--color-inverse-25);
        max-width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        position: relative;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
}

.AllBusinessesHamburgerMenuMain {
  height: calc(100% - 48px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }
}

.AllBusinessesHamburgerMenuMainTop > div {
  margin-top: 40px;
}

.AllBusinessesHamburgerMenuMainTop {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.CloseAllBusinessesHamburgerMenu {
  cursor: pointer;

  svg {
    width: 44px;
    height: 44px;

    path {
      stroke: var(--color-text-white);
    }
  }
}


.AllBusinessesHamburgerMenuSections {
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    > button {
      width: calc((100% / 2) - 8px);
      margin-bottom: 16px;
    }
  }
}

.AllBusinessesHamburgerMenuCopyRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  flex: 1;
  margin-top: 0;
  padding-bottom: 30px;

  .h7 {
    color: var(--color-text-white);
  }
}

.AllBusinessesHamburgerMenuFixProblem {
  h6,
  .h7 {
    color: var(--color-text-white);
  }

  .AllBusinessesHamburgerMenuFixProblemButton {
    margin-top: 8px;
    background: var(--color-text-black);
  }
}

.AllBusinessesHamburgerMenuLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  svg {
    width: 33px;
  }

  h4 {
    color: var(--color-text-white);
  }
}

.CustomDashboardButton {
  svg {
    path {
      stroke: var(--color-text-white);
    }
  }
}

@media (max-width: 1000px) {
  .AllBusinessesHamburgerMenuUnderlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-text-black);
    z-index: 99;
    pointer-events: none;
  }
}
