.AdminMap {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  background-color: var(--color-text-white);
  z-index: 5;
}

//.AdminMapContainer{
//  background-color: green;
//  width: 100%;
//  padding: 16px 24px;
//}

.AdminMapContainer {
  width: calc(100% - 173px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
}


.AdminMapContent {
  width: 100%;
  height: 100%;
  background-color: var(--color-text-white);

  .AdminMapStage {
    width: 100%;
    border: 10px solid var(--color-inverse-50);
    border-radius: 16px;
  }

  .konvajs-content {
    max-width: 100%;
    border-radius: 16px;
    background-color: var(--color-text-white);
    //border-radius: 16px;
    //border: 10px solid var(--color-inverse-50);
    //border-radius: 16px;
    canvas {
      max-width: 100%;
    }
  }

  .history-buttons button,
  .scale-buttons button {
    padding: 8px 16px;
    background-color: white;
    color: #000;
    border-radius: 6px;
    border: 1px solid #000;
  }

  .scale-buttons {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .history-buttons {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button:disabled {
    background-color: gray;
    cursor: not-allowed;
    opacity: 0.7;
  }

  .EmptyZoneContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    border: 10px solid var(--color-inverse-50);
    border-radius: 16px;
    overflow: hidden;

    .EmptyZoneTitle {
      font-weight: 500;
      color: var(--color-inverse-50);
      text-align: center;
    }
  }
}


.haha {
  position: absolute;
  z-index: 4;
  top: 5px;
  background-color: orange;
  border: 1px solid black;
  padding: 4px;
}


