.Review {
  .CTAButton:active {
    scale: 0.95;
  }
  .CTAButton {
    background-color: var(--color-preferred-secondary-bg);
    display: flex;
    justify-content: center;
  }
  .TextAreaWrapper {
    margin-bottom: 34px;
  }
  .TextArea {
    color: var(--color-text-white);
  }
}
.ReviewSendButton {
  &.typeB {
    &:disabled {
      cursor: not-allowed;
      background-color: var(--color-light-gray);
      scale: none;
    }
    &.isSend,
    &.isUpdate:not(:disabled) {
      background-color: var(--color-primary);
    }
    &.isSent {
      display: none;
    }
    &.isTryAgain {
      background-color: var(--color-error);
    }
  }
}
.SuccessFullySent {
  padding: 18px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  h4 {
    color: var(--color-success);
    text-align: center;
  }
  img {
    fill: var(--color-success);
  }
}

.ReviewRating {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  > div {
    h5 {
      color: var(--color-text-white);
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
