@import "src/assets/styles/_variables";

.BackButton {
  background: var(--color-background-light-mode);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 18px 0 14px;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;

  &.typeB {
    box-shadow: 2px 2px 11px -5px rgba(0, 0, 0, 0.25),
      -2px -2px 11px -2px #fbfbfb, inset 2px 2px 10px -2px #ffffff,
      inset -2px -2px 10px -8px rgba(0, 0, 0, 0.25);
  }

  &.typeC {
    justify-content: center;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 8px;
    background: var(--color-mini-button);

    svg {
      width: 10px;
    }

    &:active {
      background: var(--color-stroke);
    }
  }
  &.typeD {
    justify-content: center;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 50%;
    background: var(--color-mini-button);

    svg {
      width: 10px;
    }

    &:active {
      background: var(--color-stroke);
    }
  }
  &.typeE {
    justify-content: center;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 8px;
    background: var(--color-light-mode-black);
    svg{
      width: 10px;
      path{
        fill: var(--color-text-white);
      }

    }
  }
}
