@import "src/assets/styles/variables";

.OrderConfirm {
  cursor: default;

  .OrderConfirmHeader {

    .CloseButtonContainer {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 28px;
        height: 28px;
        flex-shrink: 0;

        path {
          stroke: var(--color-text-white);
        }
      }
    }

    .GuestModalBodyIconWrapper {
      display: flex;
      justify-content: center;
      padding: 16px 0;

      .GuestModalBodyIcon {
        width: 87px;
        height: 87px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .GuestModalBodyExclamationIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          path {
            fill: #383838;
          }
        }
      }
    }
  }

  .OrderConfirmModalBody {
    padding: 0 16px;
    width: 500px;

    .GuestModalBodyContentTitle {
      padding-bottom: 40px;
      width: 100%;
      text-align: center;
      color: var(--color-text-white);
    }

    .OrderConfirmModalBodyContentDetails {
      max-height: 200px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &::-webkit-scrollbar {
        display: none;
      }

      .OrderConfirmModalBodyContentDetail {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;

        .OrderConfirmModalBodyContentDetailContent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .OrderConfirmModalBodyContentDetailContentTitle {
            overflow: hidden;
            color: var(--color-text-white);
            text-overflow: ellipsis;
          }

          .OrderConfirmModalBodyContentDetailContentTotalPriceWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;

            .OrderConfirmModalBodyContentTotalPrice {
              overflow: hidden;
              color: var(--color-text-white);
              text-overflow: ellipsis;

              .SemiBold {
                color: var(--color-text-white);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;;
              }

              .Medium {
                color: var(--color-inverse-25);
              }
            }
          }
        }

        .OrderConfirmModalBodyContentDetailContentModifications {
          display: flex;
          padding-left: 8px;
          flex-direction: column;
          align-items: flex-start;

          .OrderConfirmModalBodyContentDetailContentModificationWrapper {
            display: flex;
            gap: 5px;
            flex-wrap: wrap;

            .OrderConfirmModalBodyContentDetailContentModification {
              overflow: hidden;
              color: var(--color-inverse-25);
              text-overflow: ellipsis;
              display: flex;
              flex-wrap: wrap;
              gap: 5px;

              .SemiBold {
                color: var(--color-inverse-25);
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
              }
            }
          }
        }
      }
    }

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .OrderConfirmModalFooter {
    padding: 40px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    .OrderConfirmModalFooterCheckbox {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .OrderConfirmModalFooterCheckboxTitle {
        color: var(--color-text-white);
        flex: 1 0 0;
      }
    }

    .GuestModalFooterButton, .DisabledGuestModalFooterButton {
      width: 100%;
      display: flex;
      padding: 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 10px;
      text-align: center;
    }

    .GuestModalFooterButton {
      background: var(--color-primary);
    }

    .DisabledGuestModalFooterButton {
      cursor: not-allowed;
      background: var(--color-mini-button);

    }

  }

}


.ModalClassname {
  background-color: var(--color-mini-button-reverse);
}