.MenuItemInfoPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.MenuItemDescriptionContainer {
  margin-bottom: 16px;
}

.MenuItemTagsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 40px;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}
