.StatsNumberData {
  border-radius: 16px;
  background: var(--color-background-light-mode);
  padding: 32px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-width: 200px;
  flex-grow: 1;

  .StatsNumberDataInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.StatsNumberDataInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .StatsNumberDataInfoTitle {
    color: var(--color-inverse-85);
  }
}

