.TagsWithIconWrapper {
  display: flex;
  gap: 12px
}

.TagWithIcon {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &.typeB {
    border: 1px solid #000;
    border-radius: 32px;
    padding: 8px 16px;

    h5 {
      text-wrap: nowrap;
    }
  }

  &.active {
    background: var(--color-light-mode-black);

    h5 {
      color: var(--color-text-white);
    }
  }

  .tagIcon {
    width: 24px;
    //height: 24px;
    //border-radius: 50%;
  }
}
