.Checkbox {
  display: flex;
  align-items: center;
  width: fit-content;

  h6 {
    margin-left: 5px;
    color: var(--color-gray);
    user-select: none;

    a {
      color: var(--color-gray);
      text-decoration-line: underline;
    }
  }

  &.typeA {
    .CheckboxIcon {
      width: 18px;
      height: 18px;
    }
  }

  &.typeB {
    .CheckboxIcon {
      width: 16px;
      height: 16px;
    }
  }

  &.typeC {
    .CheckboxIcon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    &.isChecked {
      .CheckboxIcon {
        background-color: var(--color-success);
        border: none;

        svg {
          display: block;
          width: 12px;

          path {
            stroke: var(--color-background-light-mode);
          }
        }
      }
    }
  }

  &.isChecked {
    .CheckboxIcon {
      background-color: var(--color-primary);

      svg {
        display: block;
      }
    }
  }
}

.CheckboxModalLabel {
  text-decoration: underline;
  cursor: pointer;
}

.CheckboxIcon {
  border: 1px solid var(--color-stroke);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  svg {
    display: none;
  }
}
