.MascotDashboardButton {
  display: flex;
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--color-mini-button-reverse);
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;

  > div + div {
    margin-left: 8px;
  }
}

.MascotDashboardButtonIcon {
  border-radius: 8px;
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-text-black);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  svg {
    max-width: 24px;
  }
}

.MascotDashboardButtonInfo {
  text-align: start;
  flex: 1;
  overflow: hidden;
  h6 {
    color: var(--color-text-white);
  }

  .isTruncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  p {
    color: var(--color-inverse-25);
  }
}

.MascotDashboardRightArrow {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MascotDashboardButton {
  &.isActive {
    background: var(--color-primary);

    .MascotDashboardButtonIcon {
      background: var(--color-blur-black-5);
    }

    .MascotDashboardButtonInfo {
      h6,
      p {
        color: var(--color-inverse-100);
      }
    }
  }
}
