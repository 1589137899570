.AdvanceInputBox {
  border-radius: 26px;
  overflow: hidden;
  .AdvanceInputToolsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 56px;
    padding: 5px;
    background-color: #32383e;
    position: relative;
    z-index: 2;
    color: #fff;
  }
  .AdvanceInputToolsRecording {
    width: calc(100% - 58px);
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    img {
      width: 100%;
    }
    .AdvanceInputToolsRecordingActions {
      display: flex;
      align-items: center;
      gap: 4px;
      .AdvanceInputToolsRecordingAction {
        width: 46px;
        height: 46px;
        background-color: var(--color-text-black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    h5 {
      color: var(--color-text-white);
      min-width: 45px;
    }
  }
  .AdvanceTextArea {
    resize: none;
    border: none;
    outline: none;
    display: block;
    border-radius: 1px;
    width: 100%;
    padding: 18px 16px 5px 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: #32383e;
    color: var(--color-text-white);
    caret-color: var(--color-primary);
    position: relative;
    z-index: 1;
    height: auto;
    transition: height 0.2s ease-out;
    animation: InputSlideAnimation 0.4s ease-out;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-inverse-50);
    }
  }
  .AdvanceInputTools {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 11px;


    .AdvanceInputToolsLanguages {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .AdvanceInputToolsRecordButton {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .AdvanceInputToolsPlaceholder {
      color: var(--color-inverse-50);
      flex: 1;
    }
  }
  .AdvanceInputToolsSubmitButton {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-text-white);
    &:disabled{
      background-color: var(--color-inverse-75);
    }
  }
}

@keyframes InputSlideAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
