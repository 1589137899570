@import "src/assets/styles/variables";

.SignUpForm {
  > h5 {
    margin-bottom: 16px;
  }
  .SignUpFormInputGroups {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 8px;

    > div {
      width: 100%;
    }
  }

  .AddItemModalHeaderCloseButton {
    height: 40px;
    width: 40px;
    background-color: var(--color-mini-button);

    &:hover {
      background-color: var(--color-group-background-light-mode);
    }
  }

  .SignupFormSubmitButton {
    margin-top: 56px;
  }

  @media (max-width: $mobile) {
    .PolicyModalBody {
      width: auto;
    }

    .SignupFormSubmitButton {
      position: fixed;
      width: calc(100% - 32px);
      bottom: 32px;
      left: 0;
      margin: 0 16px;
      z-index: 1;
    }
  }
}
