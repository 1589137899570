.MenuViewScrollWrapper {
  display: flex;
  gap: 15px;
  padding-left: 16px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
