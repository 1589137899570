.IconAskBill{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid var(--color-text-black);
  position: relative;

  .IconAskBillSymbol{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: var(--color-text-black);
    line-height: 14px;
    width: 14px;
    height: 14px;
    text-align: center;
  }
}