.ReservationBusinessSelection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 4px;
  margin-bottom: 80px;
  height: calc(100% - 175px);
  .GuestReservationBusinessSelection{

  }
  .ReservationBusinessSelectionSearch {
    width: 100%;
    max-width: none;
    border: none;
    border-radius: 8px;
    background: var(--color-mini-button);

    .SearchInput {
      &::placeholder {
        color: var(--color-inverse-50);
      }
    }
  }

  .ReservationBusinessSelectionAllBusiness {
    .GuestReservationLoading{
      width: 100%;
      height: 100%;
    }
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(40px, 1fr));
    grid-template-rows: repeat(3, minmax(40px, 1fr));
    justify-content: flex-start;
    gap: 16px;
  }

  .ReservationBusinessSelectionOneBusiness {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    width: 100%;
    height: auto;
    position: relative;

    .ReservationBusinessSelectionLogoOuter {
      border-radius: 8px;
      border: 0.623px solid var(--color-stroke);
      padding: 1px;
      height: 100%;
      position: relative;

      .ReservationBusinessSelectionLogo {
        border-radius: 8.037px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 1;
        }
      }
    }
    .ReservationBusinessSelectionTitle {
      .BusinessName {
        overflow: hidden;
        width: 90%;
        color: var(--color-inverse-100);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .SelectedBusiness {
    .ReservationBusinessSelectionLogoOuter {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: rgba(255, 199, 0, 0.75);
        z-index: 5;
      }
    }
  }
  .BusinessSelectCheckIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  .ReservationEmptyState {
    position: absolute !important;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    height: auto;
    width: inherit;
  }
}
