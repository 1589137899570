@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.MenuItemCard {
  position: relative;
  background: var(--color-background-light-mode);
  padding: 5px;
  border-radius: 8px;
  border: 1px solid var(--color-stroke);
  overflow: hidden;
  width: 110px;
  min-width: 110px;
  height: 146px;
  cursor: pointer;
  &.isSelected {
    border: 1px solid var(--Light-Mode-Primary, #FD0);
    background: var(--light-mode-primary-15, #FFFAD9);


  }
  .RadioButtonContainer{
    width:24px ;
    height: 24px;
  }
}

.MenuItemCardImg {
  height: 82px;
  position: relative;
  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
}

.MenuItemCardInfo {
  padding-top: 6px;
}

.MenuItemCardName {
  color: var(--color-inverse-85);
  margin: 0;
  @include text-truncate-ellipsis(1);
}

.MenuItemCardInfoBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.MenuItemCardPrice {
  padding-bottom: 6px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  > * + * {
    margin-left: 5px;
  }
}