@import "src/assets/styles/_variables";

.MenuItemDescription {
  margin: 0;
  padding: 16px 0 32px 0;
  font-weight: 400;
  color: var(--color-text-black);
  text-wrap: wrap;

  &.typeA {
    font-size: 14px;
    line-height: 20px;
  }

  &.typeB {
    font-size: 11px;
    line-height: 13px;
  }
}
