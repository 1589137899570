@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.MenuViewScroll {
  width: 280px;
  min-width: 280px;
  cursor: pointer;
  background-color: var(--color-background-light-mode);
  padding: 6px 6px 10px 6px;
  border: 1px solid var(--color-stroke);
  border-radius: var(--radius-preferred-border-radiusD);

  &:last-child {
    margin-right: 16px;
  }
}

.MenuViewScrollTop {
  position: relative;
  height: 303px;
}
.MenuViewScrollTagsWithIcon {
  display: flex;
  gap: 8px;
  overflow: auto;
  scrollbar-width: none;
  padding-top: 20px;
  margin-right: 50px;
  min-height: 44px;
}

.MenuViewScrollImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-preferred-border-radiusE);
}
.MenuViewScrollTagsAndAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MenuViewScrollTags{
  overflow: auto;
}

.MenuViewScrollFavoritesButton {
  position: absolute;
  z-index: 4;
  top: 5px;
  right: 5px;
}

.MenuViewScrollDiscountRate {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.MenuViewScrollMiddle {
  padding-top: 8px;
}

.MenuViewScrollName {
  color: var(--color-text-black);
  margin-bottom: 8px;
  min-height: 40px;

  @include text-truncate-ellipsis();
}

.MenuViewScrollBottom {
  position: relative;
  min-height: 44px;
}
.MenuViewScrollPriceAndTags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  margin-right: 8px;
}

.MenuViewScrollPrice {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: 54px;
}

.MenuViewScrollAddButton {
  //position: absolute;
  //bottom: 0;
  //right: 0;
  width: 44px;
  height: 44px;
  margin-left: auto;
}
