@import "src/assets/styles/variables";

@mixin flex {
  display: flex;
  justify-content: space-between;
}

.MenuViewGrid {
  aspect-ratio: 1;
  min-width: 0;
  @include flex();
  max-width: 100%;
  flex-direction: column;
  padding: 8px;
  position: relative;
  cursor: pointer;
}
.MenuViewGridImg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: -1;
}

.MenuViewGridPrice {
  padding: 4px 8px;
  border-radius: var(--radius-preferred-border-radiusK);
  background-color: var(--color-background-light-mode);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    &:nth-child(2) {
      margin-top: 4px;
    }
  }
}

.MenuViewGridTopIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 4;
}

.MenuViewGridBottomIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 4;
}
