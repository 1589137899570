.WheelOfFortuneBanner {
  cursor: pointer;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  border: 2px solid var(--color-purple);
  background: var(--color-purple-20);
  backdrop-filter: blur(13px);
  width: 50%;

  &::before {
    content: "";
    position: absolute;
    width: 9px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 3px;
    background: var(--color-purple);
  }

  &::before {
    left: -5px;
  }
}

.WheelOfFortuneBannerContent {
  height: 100%;
  padding-left: 15px;
  width: 74%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 6px;
  padding-bottom: 16px;
}

.WheelOfFortuneBannerContentTitle {
  color: var(--color-text-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.WheelOfFortuneBannerSpinner {
  height: 100%;
  position: relative;
}

.WheelOfFortuneBannerSpinnerImage {
  svg {
    position: absolute;
    right: -36px;
    bottom: -2px;
    width: 70.722px;
    height: 70.722px;
    border-radius: 70px;
    border: 2px solid var(--color-purple);
    background: var(--color-purple);
    animation: 10s rotate linear infinite;
  }
}
