.ReservationTableSelectionType {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-top: 40px;
  padding-bottom: 104px;
  &::-webkit-scrollbar {
    display: none;
  }
  span {
    font-size: 18px;
    color: var(--color-inverse-75);
    font-weight: 600;
    line-height: 20px; /* 125% */
  }
}

.ReservationTableSelectionTypeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.GuestInfoFormDetailsVirtualTourPreview {
  width: calc((100% - 16px) / 2);
  min-height: 242px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 0 8px 8px 8px;
  display: flex;
  align-items: flex-end;

  img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    object-fit: cover;
  }
}

.GuestInfoFormDetailsVirtualTourReservation {
  width: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;

  h6 {
    color: var(--color-text-white);
  }

  svg {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }
}
