@import "src/assets/styles/mixins";

.AdminOrderMapSection {
  width: 100%;
  //background-color: var(--color-mini-button);
  .ModalBackground {
    .ModalContainer {
      .Modal {
        .ModalBody {
          overflow-y: visible !important;
        }

      }
    }
  }
}

.AdminOrderMapTitle {
  padding: 16px 30px;
  background: var(--color-background-light-mode);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;

  .AdminOrderMapTitleInfoAndActionButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.AdminOrderMapTitleInfo {
  display: flex;
  gap: 16px;
}

.AdminOrderMapSections {
  display: flex;
}

.AdminOrderFullTables {
  .DropdownSelectedItem {
    width: 185px;
    height: 64px;
    border: 1px solid var(--color-gray);
    max-width: 250px;
  }

  .DropdownLabel {
    background-color: transparent;
    color: var(--color-text-black);
  }
}

.AdminOrderMapSectionGuests {
  padding: 8px;
  min-height: calc(100vh - 96px);
  max-height: calc(100vh - 96px);
  width: 30%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  scrollbar-width: none;

  .EmptyStateContainer {
    img {
      width: 120px;
      height: 120px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .AdminOrderMapSectionGuestsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .AdminOrderMapSectionGuest {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;

      &:hover {
        background-color: var(--color-mini-button);
      }
    }

    .AdminOrderMapSectionGuestInfo {
      min-width: 132px;
      position: relative;
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 0 8px 8px;
      cursor: pointer;
      border-radius: 8px;

      .GuestInfoProfilePicContainer {
        position: relative;

        .BirthdayHat {
          transform: rotate(-45deg);
          position: absolute;
          width: 32px;
          height: 32px;
          left: -16px;
          top: -18px;
        }

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
          font-size: 10px;
          font-weight: 500;
          color: var(--color-inverse-50);
        }
      }


    }

    .HappyBirthdayContainer {
      width: 50px;
      height: 34px;
      margin-right: 8px;

      .HappyBirthday {
        border-radius: 0;
        width: 50px;
        height: 34px;
      }
    }
  }
}

.AdminOrderNumberOfGuests {
  background-color: var(--color-mini-button);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  justify-content: center;
  padding: 10px 16px;
}

.AdminOrderMapGuestsInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  align-self: flex-end;
}

.AdminOrderMapTitleActionButtons {
  display: flex;
  gap: 16px;
}

.AdminOrderChatButton {
  svg {
    width: 24px;
    height: 24px;
  }
}

.AdminOrderButtonNotifications {
  width: 24px;
  height: 24px;
  background-color: var(--color-admin-primary);
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    color: var(--color-text-black);
  }
}

.AdminOrderMapSectionZones {
  display: flex;
  overflow-x: auto;
  padding: 0 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.AdminOrderMapSectionZoneItemBox {
  padding: 0 6px 8px 6px;

  &.isActive {
    position: relative;
    padding: 0 6px 0 6px;

    .AdminOrderMapSectionZoneItem {
      background-color: var(--color-group-background-light-mode);
      height: 56px;
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      left: -14px;
      border-bottom-right-radius: 8px;
      box-shadow: 8px 8px 0 0 var(--color-group-background-light-mode);
    }

    &:after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: -14px;
      border-bottom-left-radius: 8px;
      box-shadow: -8px 8px 0 0 var(--color-group-background-light-mode);
    }
  }
}

.AdminOrderMapSectionZoneItem {
  padding: 16px 24px;
  background-color: var(--color-background-light-mode);
  border-radius: 8px;
  cursor: pointer;

  h6 {
    color: var(--color-text-black);
    white-space: nowrap;
  }

  &:hover {
    background-color: var(--color-group-background-light-mode);
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.AdminOrderMapSectionTables {
  background-color: var(--color-mini-button);
  width: 100%;
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  min-height: calc(100vh - 96px);
  align-content: flex-start;

  .EmptyStateContainer {
    width: 100%;
    height: calc(100vh - 200px);
  }

  .AdminOrderMapSectionTableItemTitle {
    word-break: break-word;
    @include text-truncate-ellipsis();
  }
}

.AdminOrderMapSectionTableItem {
  width: 130px;
  height: 160px;
  border-radius: 10px;
  background: var(--color-text-white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 8px 10px 8px;
  margin-bottom: 16px;
  margin-right: 16px;

  &:hover {
    background-color: var(--color-gray);
  }

  .TableText {
    color: var(--color-text-black);
  }

  .ZoneText {
    color: var(--color-text-black);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &.hasOrder {
    background: #5c2bc4;

    .TableText {
      color: var(--color-text-white);
    }

    .ZoneText {
      color: var(--color-gray);
    }
  }

  &.newNotification {
    animation: backgroundColorChange 2s infinite ease;
  }
}

.AdminOrderMapSectionTableItemActionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  svg {
    width: 28px;
    height: 28px;
    filter: opacity(0.1);

    &.isActive {
      filter: opacity(1);
    }
  }
}

.AdminOrderOption {
  svg {
    width: 32px;
    height: 32px;

    path {
      fill: var(--color-text-black);
    }
  }
}

.AdminOrderMapSectionTableItemActions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;

  svg {
    * {
      color: var(--color-text-white);
      fill: var(--color-text-white);
    }
  }

  .IconAskBill {
    width: 28px;
    height: 28px;
    opacity: 0.1;
    border-color: var(--color-text-white);

    .IconAskBillSymbol {
      color: var(--color-text-white);
      line-height: 17px;
      width: 18px;
      height: 18px;
      font-size: 21px;
    }

    &.isActive {
      opacity: 1;
    }
  }
}

@keyframes backgroundColorChange {
  0% {
    background-color: #5c2bc4;
  }
  50% {
    background: rgba(#f39237, 1);
  }
  100% {
    background-color: #5c2bc4;
  }
}
