.GuestInfoForm {
  margin-top: 4px;
  position: relative;
  margin-bottom: 100px;

  .GuestInfoFormContact {
    margin-top: 40px;

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-inverse-100);
      margin-bottom: 16px;
    }

    .ContactForm {
      .GuestInfoFormContactPhoneNumber {
        margin-top: 16px;
      }
    }
  }

  .GuestInfoFormDetails {
    margin: 40px 0 80px;

    .GuestInfoFormContainer {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-inverse-100);
      margin-bottom: 16px;
    }

    .ReservationDateAndTime {
      margin-top: 16px;
      display: flex;

      .InputControlContainer,
      .Dropdown {
        width: 50%;

        &:not(:nth-child(1)) {
          margin-left: 10px;
        }
      }
    }

    .GuestReservationDuration {
      margin-top: 16px;

      p {
        font-size: 12px;
        font-weight: 400;
        margin-top: 7px;
      }
    }

    .GuestReservationComment {
      margin-top: 16px;
    }
  }

}

.ReservationSectionTitle {
  display: flex;
  gap: 8px;
  align-items: center;

  .ReservationSectionTitleContent {
    display: flex;
    align-items: center;
    cursor: pointer;

    .ReservationSectionTextToolTip {
      display: none;
      position: absolute;
      top: 70%;
      left: 0;
      background-color: var(--color-background-light-mode);
      border-radius: 4px;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
      padding: 8px;
      width: 232px;
      overflow: auto;
    }

    &:hover {
      .ReservationSectionTextToolTip {
        display: block;
      }
    }
  }


}


.ReservationSelectionDuration {
  width: fit-content;
}

.ReservationSelectionCount {
  min-width: 56px;
}

.ReservationNumberOfGuestAndDuration {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  margin-top: 12px;
  &::-webkit-scrollbar{
    display: none;
  }
}

.ReservationSelectionCount,
.ReservationSelectionDuration {
  border-radius: 24px;
  background-color: var(--color-mini-button);
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  color: var(--color-text-black);
  white-space: nowrap;
  align-items: center;
  cursor: pointer;

  &.isActive {
    background-color: var(--color-preferred-secondary-bg);
    color: var(--color-preferred-secondary-text);
  }
}
