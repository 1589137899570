.StatsPieChartWithNeedleWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  //border-radius: 8px;
  //background: var(--color-text-white);
  //box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25) inset;
  height: 72px;
  min-width: 285px;
  .AdminOrderFullnessOfTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    background: var(--color-mini-button);
    height: 100%;
    padding: 8px 16px;
  }
  .AdminOrderFullTablesStatistics {
    display: flex;
    text-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    h6:first-child {
      color: var(--color-inverse-75);
    }
  }
  .AdminOrderMapGuestsInfos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .AdminOrderMapGuestsInfo {
    padding: 4px 8px;
    border-radius: 8px;
    background: var(--color-mini-button);
    min-width: 85px;
    width: 100%;

    .AdminOrderMapGuestsInfoInner {
      display: flex;
      gap: 4px;
      align-items: center;

    }
  }
}
