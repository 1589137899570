@import 'src/assets/styles/variables';

.SetUserPin {
  .Subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .SetUserPinInputGroups {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 56px;
  }

  .SetUserPinButtons {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > :nth-child(1) {
      min-width: 90px;
      flex: 1;
    }

    > :nth-child(2) {
      flex: 2.8;
    }
  }
}

@media (max-width: $mobile) {
  .SetUserPinButtons {
    position: fixed;
    width: calc(100% - 32px);
    bottom: 32px;
    right: 0;
    margin: 0 16px;
    z-index: 1;
  }
}
