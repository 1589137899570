.BasketHeroSection {
  padding: 16px 16px 0;
  margin-bottom: 32px;
  background: var(--color-light-mode-black);
  border-radius: 0px 0px 16px 16px;
    .BasketHeaderTitle {
      color: var(--color-text-white);
    }

}

.BasketHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .BasketHeaderTitle {
    color: var(--color-text-white);
  }

  .BackButton {
    background: var(--color-mini-button);

    svg {
      path {
        fill: var(--color-light-mode-black);
      }
    }
  }
}
.BasketFavoriteEmpty{
  padding: 24px 0px;
  h5{
    color: var(--color-text-white);
  }
}

.BasketFavoriteItemsContainer {
  margin-top: 24px;
  h5{
    color: var(--color-text-white);
  }
  .BasketFavoriteItemsSeeAll{
    color: var(  --color-secondary);
  }
}
.BasketFavoriteItemsLikes{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BasketFavoriteItemsWrapper {
  display: flex;
  overflow-x: auto;
  margin-top: 8px;
  padding-bottom: 25px;

  > * + * {
    margin-left: 8px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
  }
}
