@import "src/assets/styles/_variables";

.OrderInfoWrapper {
  overflow: hidden;
}

.OrderInfo {
  border-radius: 12px;
  border: 1px solid var(--color-stroke);
  background-color: var(--color-mini-button-reverse);
}

.OrderInfoTitle {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  border-radius: 12px;
  background-color: var(--color-mini-button-reverse);
  .OrderInfoTitleLogo {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: var(--color-inverse-85);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  span {
    color: var(--color-text-white);
  }

  svg {
    width: 36px;
    height: 36px;
  }
}


.OrderInfoWrapper .PromoForm {
  display: flex;
  padding: 0 16px;
  margin: 30px 0;
  input {
    flex-grow: 1;
  }
  button {
    margin-left: 9px;
  }
}

.OrderInfoBorder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: var(--color-mini-button-reverse);

  &:after {
    content: "";
    top: 0;
    left: 9px;
    width: calc(100% - 20px);
    height: 100%;
    position: absolute;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.25);
    z-index: -1;
  }
}

.OrderInfoLine {
  position: relative;
  background-color: var(--color-mini-button-reverse);

  background-image: linear-gradient(to right, #d9d9d9 50%, transparent 50%);
  background-position: center;
  background-size: 20px 1px;
  background-repeat: repeat-x;
  width: calc(100% - 18px);
  height: 100%;

  &:after {
    position: absolute;
    top: 0;
    right: -18px;
    width: 18px;
    height: 18px;
    background: var(--color-background-light-mode);
    z-index: 1;
    content: "";
    border: 1px solid var(--color-stroke);
    border-radius: 100%;
  }

  &:before {
    position: absolute;
    top: 0;
    left: -18px;
    width: 18px;
    height: 18px;
    background: var(--color-background-light-mode);
    z-index: 1;
    content: "";
    border-radius: 50%;
    border: 1px solid var(--color-stroke);
  }
}

.OrderInfoCenter {
  background-color: var(--color-mini-button-reverse);

  padding-bottom: 25px;

  &:before {
    content: "";
    background: var(--color-stroke);
    display: flex;
    justify-content: center;
    height: 1px;
    margin: 0 16px;
  }

  & > * + * {
    margin-top: 15px;
  }

}

.OrderInfoDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  &:first-child {
    margin-top: 30px;
  }

  h6 {
    color: var(--color-text-white);
    display: flex;
    align-items: center;
    span{
      color: var(--color-text-white);
    }
  }
}

.OrderInfoTip {
  position: relative;
  display: flex;
  margin-top: 5px;
}

.OrderInfoTotal {
  background-color: var(--color-mini-button-reverse);
  padding: 19px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  h3,
  h6 {
    color: var(--color-text-white);
  }
}
