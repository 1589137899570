@import "src/assets/styles/_variables";

//.Hahaha {
//  height: 100dvh;
//  width: 100%;
//  position: relative;
//  left: 0;
//  top: 0;
//  z-index: -1;
//}

.BasketButtonContainer {
  position: fixed;
  bottom: 30px;
  right: 16px;
  z-index: 5;
}

.BasketButtonWrapper {
  position: relative;
  display: flex;
  flex-flow: row-reverse;
}

.BasketButtonCount {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  top: -1px;
  left: 0;
  background: var(--color-background-black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  h6 {
    color: var(--color-text-white);
  }
}

.BasketButtonAnimation {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 60px;
  height: 60px;
  border-radius: 42px;
  background: var(--color-preferred-secondary-bg);
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 12px 20px;
  animation-timing-function: ease-in-out;
  animation-duration: 1.5s;
  transition: 1.5s;
  animation-play-state: paused;
  visibility: hidden;
  overflow: hidden;

  h6 {
    color: var(--color-preferred-secondary-text);
    word-break: break-word;
    font-size: 14px;
    width: 150px;
  }

  &.show {
    animation-play-state: running;
    visibility: visible;
  }
}

@keyframes move {
  0% {
    width: 68px;
  }
  10% {
    min-width: 240px;
    max-width: 300px;
    margin-right: 12px;
  }
  90% {
    min-width: 240px;
    max-width: 300px;
    margin-right: 12px;
  }
  100% {
    width: 68px;
  }
}

.BasketButton {
  width: 68px;
  height: 68px;
  border-radius: 42px;
  background: var(--color-preferred-secondary-bg);
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  z-index: 2;

  &:active {
    scale: 0.95;
  }

  svg {
    path {
      stroke: var(--color-preferred-secondary-text);
    }

    circle {
      fill: var(--color-preferred-secondary-text);
    }
  }
}
