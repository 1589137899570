@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.AdminOrderPageHomeInfoBox {
  width: 50%;
  //height: 330px;
  background: var(--color-inverse-85);
  border-radius: 12px;
  padding: 16px;
  position: relative;
  overflow: hidden;
  //overflow: auto;
  //transition: max-height 0.5s;
  //transition:  0.5s ease-in-out, opacity 0.5s ease-in-out;
  //transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: auto;

  //&::-webkit-scrollbar {
  //  display: none;
  //}

  &:not(:nth-child(1)) {
    margin-left: 24px;
  }

  @media (max-width: $desktop) {
    width: 100%;
  }
  @media (max-width: $tablet) {
    margin-top: 16px;
  }

  .AdminOrderPageHomeInfoBoxHeader {
    border-bottom: 1px solid var(--color-stroke);
    display: flex;
    text-align: center;

    h3 {
      padding-bottom: 16px;
      text-align: center;
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      color: var(--color-text-white);
    }
  }

  .AdminOrderPageHomeInfoBoxPrice {
    margin-top: 8px;

    .AdminOrderPageHomeInfoBoxPriceLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .AdminOrderInfoDetails,
      h4,
      h3 {
        color: var(--color-text-white);
        font-size: 20px;
      }

      h6,
      h3 {
        color: var(--color-text-white);
        font-weight: 500;
        display: flex;
        align-items: center;

        span {
          color: var(--color-text-white);
        }

        h5,
        h6 {
          font-weight: 500;
          color: var(--color-text-white);
        }
      }

      .AdminOrderPageHomeInfoBoxAddDiscount {
        flex-direction: row-reverse;
        padding: 9px 16px;
        border: 1px solid var(--color-admin-primary);
        width: auto;

        svg {
          width: auto !important;
          height: auto !important;
          margin-left: 4px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .AdminOrderPageHomeInfoBoxPrint {
    border-radius: 10px;
    width: 100%;
    height: auto;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background-color: var(--color-admin-primary);
    flex-direction: row;
    margin-top: 34px;
    margin-left: 0;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 10px;

      path {
        fill: var(--color-text-black);
      }
    }

    &:hover {
      background-color: var(--color-background-light-mode);
      border: 1px solid var(--color-admin-primary);
    }

    &:active {
      border: 1px solid var(--color-admin-primary);
      background-color: var(--color-admin-primary-disabled);
    }
  }

  .AdminOrderPageHomeInfoBoxOrderDetails {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    //transition: max-height 0.5s;

    .OrderDetailsButton {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 24px;

      p {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-text-white);
      }

      svg {
        width: 12px;
        height: 6px;
        transition: 0.5s;

        path {
          fill: var(--color-text-white);
        }
      }

      .RotateArrowIcon {
        transform: rotate(180deg);
        transition: 0.5s;
      }
    }

    .OrderDetails {
      display: flex;
      flex-direction: column;
      padding-top: 18px;
      list-style: none;
      width: 100%;

      li {
        //&:not(:nth-child(1)) {
        //  margin-top: 8px
        //}
        //margin-top: 8px;
        .AdminOrderInfoDetails {
          width: 100%;
          display: flex;
          justify-content: space-between;

          p,
          .Price,
          h6 {
            color: var(--color-text-white);
            font-size: 12px;
            font-weight: 500;
            width: auto;

            span {
              color: inherit;
              font-size: 14px;
              font-weight: 700;
              margin-right: 10px;
            }
          }

          > p {
            margin-right: 8px;
          }

          .Price {
            h6 {
              font-size: 14px;
              font-weight: 500;
              color: var(--color-text-white);
              width: auto;
            }
          }
        }

        .OrderItemDetailsListModifications {
          margin: 4px 8px 0;

          .OrderItemDetailsListModificationList {
            h6 {
              word-break: break-word;
              color: var(--color-text-white);
            }
          }
        }
      }
    }
  }
}

.AdminOrderInfoPrice {
  display: flex;
  gap: 4px;
  align-items: center;

  .Price {
    div {
      font-size: 14px;
      color: var(--color-inverse-25);
    }
  }
}

.AdminOrderPageHomeInfo {
  width: 50%;
  margin-left: 24px;

  .ModalBackground {
    .ModalContainer {
      .Modal {
        .ModalBody {
          overflow-y: visible !important;
        }

      }
    }
  }

  .AdminOrderPageGuests {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding-bottom: 30px;

    .AdminOrderPageGuest {
      position: relative;
      background-color: var(--color-text-white);
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 8px;
      width: 100%;
      gap: 8px;

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
      }

      .BirthdayHat {
        transform: rotate(-45deg);
        position: absolute;
        left: -8px;
        top: -9px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .AdminOrderPageHomeOrderInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: $desktop) {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
  @media (max-width: $tablet) {
    margin: 0;
  }
}

.SetAutoHeight {
  height: auto;
}

.AdminOrderPageHomeInfoBoxDiscountModal {
  margin-top: 30px;
  padding-bottom: 40px;
  width: 500px;

  .AdminOrderPageHomeInfoBoxDiscount {
    margin-top: 16px;
    margin-bottom: 35px;

    .AdminOrderPageHomeInfoBoxDiscountIsFixed {
      margin-bottom: 16px;

      label {
        &:not(:nth-child(1)) {
          margin-left: 8px;
        }
      }
    }

    .InputControlDefinition {
      width: 36%;
    }

    .InputControlInput {
      padding-right: 36%;
    }
  }
}
