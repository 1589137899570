.AnimatedPlusMinusButtonContainer {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  height: fit-content;
  justify-content: center;
  position: relative;

  button {
    width: 40px;
    height: 40px;
    //flex-shrink: 0;
    border: none;
    border-radius: 6px;
    background-color: var(--color-mini-button);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
    transition: width 0.2s ease;

    &:not(:disabled):active {
      background-color: var(--color-group-background-light-mode);
    }

    img {
      width: 16px;
      height: 16px;
    }

    &.isActive {
      img {
        filter: invert(70%) sepia(49%) saturate(1195%) hue-rotate(331deg)
          brightness(95%) contrast(100%);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }

  &.TYPE_MODIFICATION {
    gap: 2px;

    button {
      border-radius: 8px 0 0 8px;
      background: var(--color-mini-button);
      width: 50px;
      height: 32px;
      img {
        width: 10px;
        height: 10px;
      }
      &.isActive {
        img {
          filter: inherit;
        }
      }
    }
    button:nth-child(2) {
      border-radius: 0 8px 8px 0;
    }
  }

  .AnimatedPlusMinusButtonPrice {
    color: var(--color-text-black);
    min-width: 30px;
    text-align: center;
  }

  &.showAnimatedCount {
    border: 1px solid var(--color-orange);

    .Price {
      color: #f39237;
    }
  }

  &.hasBorder {
    padding: 7px 18px;
    border-radius: 12px;
    border: 1px solid var(--color-inverse-100);
    width: 40%;
    z-index: 10;
    background: var(--Light-Mode-Text-White, #fff);

    button {
      background: none;
    }

    p {
      flex-grow: 1;
      text-align: center;
    }
  }
}

.AnimatedPlusMinusButtonAnimate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--color-secondary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: lift-up-and-disappear 0.5s ease-out;
  animation-fill-mode: forwards;

  h5 {
    color: var(--color-text-white);
  }
}

//animation
@keyframes lift-up-and-disappear {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  90% {
    transform: translate(-50%, -200%);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -200%);
    opacity: 0;
  }
}
