@import "src/assets/styles/mixins";

.ProfilesInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  padding: 6px;

  p {
    font-size: 12px;
    line-height: 17px;
  }

  .TableName {
    padding-bottom: 4px;
  }

  .AllGuestsCount {
    color: var(--color-inverse-75);
    padding-left: 2px;
  }

  .SelectedGuestsCount {
    color: var(--color-inverse-75);
  }
}

.GuestProfiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  padding-left: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * + * {
    margin-left: 9px;
  }

  .AllGuestsButton {
    padding: 14px 17px;
    background-color: white;
    width: auto;

    .CTAButtonName {
      font-weight: 500;
    }

    &.isAllGuestsButtonActive,
    &.isNoGuestsSelected {
      background-color: var(--color-primary);
    }
  }

  div {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .GuestProfile {
    background-color: var(--color-mini-button);
    padding: 8px;
    height: 134px;
    min-width: 88px;
    width: 88px;

    &.isActive {
      background: var(--color-preferred-secondary-bg);
      h6 {
        color: var(--color-preferred-secondary-text);
      }
    }


    &:last-child {
      margin-right: 6px;
    }

    h6 {
      @include text-truncate-ellipsis(2);
      text-align: center;
      word-break: break-word;
    }

    .GuestTotalPrice {
      margin-top: 8px;
    }
  }

  .GuestProfileIdentity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .GuestProfileName {
      width: 78px;
      height: 40px;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 8px;
    flex-shrink: 0;

  }
}
