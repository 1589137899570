.RadioSelection {
  cursor: pointer;
  width: fit-content;
  display: inline-block;

  &.isDisabled {
    .RadioSelectionText {
      color: var(--color-inverse-50);
    }
  }

  &.hasImage {
    border-radius: 12px;
    background: var(--color-group-background-light-mode);
    border: 3px solid transparent;
    padding: 16px;
    width: 212px;

    &.isSelected {
      border: 3px solid var(--color-admin-primary);
    }

    .RadioSelectionImage {
      max-width: 100%;
      margin-top: 9px;
    }
  }
}

.RadioSelection.typeYellow {
  &.isSelected {
    .RadioSelectionCircle {
      border: 1px solid var(--color-admin-primary);
    }
  }
}

.RadioSelectionHeader {
  display: flex;
  align-items: center;
}

.RadioSelectionInput {
  opacity: 0;
  position: absolute;

  &:checked+.RadioSelectionCircle::before {
    transform: translate(-50%, -50%) scale(1);
  }
}

.RadioSelectionCircle {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-stroke);
  background-color: var(--color-background-light-mode);
  border-radius: 50%;
  margin-right: 12px;
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: var(--color-admin-primary);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }
}

.RadioSelectionDescription {
  color: var(--color-inverse-25);
}
