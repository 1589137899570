@import "src/assets/styles/mixins";

.DraggableCardContainer {
  min-height: fit-content;
  background-color: var(--color-mini-button);
  padding: 12px 16px;
  border-radius: 16px;
  margin-bottom: 16px;

  &.isDragging,
  &.isHeaderHovered {
    background-color: var(--color-group-background-light-mode);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .DraggableCardHeader {
    width: 100%;
    display: flex;
    align-items: center;

    .DraggableCardActionButtons {
      display: flex;
      gap: 10px;
      margin-left: auto;

      .EditButtonIcon {
        width: 24px;
      }
    }

    .DraggableCardLabel {
      font-weight: 900;
      color: var(--color-inverse-25);
    }

    .DraggableCardDragAndLabel {
      display: flex;
      align-items: center;
    }

    &.sizeL {
      .DraggableCardItemNameContainer {
        display: flex;
        align-items: center;
        margin-right: 16px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .DraggableCardDragAndLabel {
        margin-right: 32px;
        gap: 8px;

        .DragDropIcon {
          width: 24px;
        }
      }
    }

    &.sizeS {
      .DraggableCardDragAndLabel {
        margin-right: 16px;
        gap: 4px;

        .DragDropIcon {
          width: 16px;
        }
      }

      .DraggableCardItemName {
        @include text-truncate-ellipsis(2);
      }
    }
  }

  .DraggableCardItemName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: initial;
  }

  .DraggableCardContent {
    display: flex;
    flex-direction: row;
    width: 100%;

    .DraggableCardLeftContainer {
      margin-top: 20px;
      width: calc(100% - 300px);

      &.hasNoItem {
        width: auto;
      }
    }

    .DraggableCardRightContainer {
      margin-top: 20px;
      width: 300px;

      .DraggableListVerticalContainer {
        padding: 0 0 0 16px;
      }

      .DraggableCardContainer {
        display: flex;
        height: 46px;
        background-color: var(--color-text-white);
        margin-bottom: 8px;
        border-radius: 10px;

        &:hover,
        &.isDragging {
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
