.Steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: contents;
  }
  .Step {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background: white;
    &:nth-last-child(-1) {
      width: auto;
    }

    .StepperRadioButtonContainer {
      display: flex;
      align-items: center;
      .StepRadioButton {
        width: 30px;
        height: 30px;
        opacity: 0;
        position: absolute;

        &:checked + .StepRadioButtonSelectionCircle::before {
          transform: translate(-50%, -50%) scale(1);
          background-color: var(--color-admin-primary);
        }
        &:checked + .StepRadioButtonSelectionCircle {
          background-color: transparent;
          border: 1px solid var(--color-admin-primary);
        }
      }

      .StepRadioButtonSelectionCircle {
        width: 30px;
        height: 30px;
        background-color: var(--color-inverse-25);
        border-radius: 50%;
        display: inline-block;
        position: relative;

        &::before {
          content: '';
          width: 18px;
          height: 18px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    .StepRadioButtonSelectionCircleComplete {
      background-color: var(--color-success) !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: var(--color-background-light-mode);
        }
      }
    }

    .StepTitle {
      color: var(--color-text-black);
      font-size: 14px;
      font-weight: 500;
      padding-top: 16px;
    }
  }
  .AlignStepToCenter {
    align-items: center;
  }
  .AlignStepToRight {
    align-items: flex-end;
  }
}
.StepperProgressLine {
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background: var(--color-admin-primary);
  position: relative;
  top: -16px;
  transition: background-color 0.4s;
}

.StepperProgressLineGray {
  background-color: var(--color-inverse-25);
}

.StepperProgressLineGreen {
  background-color: var(--color-success);
}
