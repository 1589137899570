.AdminBusinessGuests {
  padding: 40px 32px 55px;

  .BodyRow {
    position: relative;

    .BirthdayHat {
      transform: rotate(-45deg);
      position: absolute;
      width: 32px;
      height: 32px;
      top: -7px;
      left: 0;
    }
  }

  .ModalBackground {
    .ModalContainer {
      .Modal {
        padding-top: 0;
        overflow: scroll;
        scrollbar-width: none;
        .ModalHeader{
          position: sticky;
          margin-bottom: 30px;
          z-index: 2;
        }
        .ModalBody {
          overflow-y: visible !important;
        }
        .ModalFooter{
          position: static;
        }
      }
    }
  }

  .ThreeDotsContainer {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4.8px;
    background: var(--color-mini-button);
    svg {
      width: 24px;

      circle {
        fill: transparent;
        stroke: var(--color-text-black);
      }
    }
  }

  .AdminBusinessGuestsHeader {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .AdminBusinessGuestsFilters {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      justify-content: flex-end;

      .Dropdown {
        max-width: 150px;
        width: 100%;
      }

      .AdminFiltersSearch.isDisabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .AdminBusinessGuestsTitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      .AdminBusinessGuestsTitleText {
        color: var(--color-text-black);
      }

      .AdminBusinessGuestsCount {
        color: var(--color-inverse-50);
      }
    }
  }


  .AdminBusinessGuestProfileImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.AdminBusinessGuestTableEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 48px;

  .AdminBusinessGuestTableEmptyInfo {
    color: var(--color-inverse-50);
  }
}
