@import "src/assets/styles/_variables";

.InputPassword {
  position: relative;
  display: flex;
  flex-direction: column;
}

.InputPasswordContainer {
  display: flex;
  flex-direction: column;

  &.TypeA {
    .InputPasswordLabel {
      left: 18px;
      top: 9px;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      &::before {
        background-color: transparent;
      }
    }

    .InputPasswordInput {
      background: var(--color-mini-button);
      border-radius: 30px;
      border: 1px solid var(--color-mini-button);
      padding-left: 24px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        box-shadow: 0 0 0 1000px var(--color-mini-button) inset;
      }


      &::placeholder {
        padding-left: 24px;
      }


      &:placeholder-shown {
        ~ .InputPasswordLabel {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          top: 50%;
          left: 18px;
        }
      }

      &:focus {
        border: 1px solid var(--color-primary);

        ~ .InputPasswordLabel {
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          top: 9px;
          left: 18px;
        }
      }
    }
  }
}

.InputPasswordLabel {
  display: flex;
  align-items: flex-start;
  position: absolute;
  left: 8px;
  transform: translateY(-50%);
  padding: 0 8px;
  color: var(--color-inverse-50);
  top: 0;
  transition: $defaultTransition;
  pointer-events: none;
  font-size: 12px;
  font-weight: 500;

  &.typeA {
    &:before {
      background-color: var(--color-mini-button);
    }
  }

  &.typeB {
    &:before {
      background-color: var(--color-background-light-mode);
    }
  }

  &.noFloating {
    display: none;
    background: transparent;

    &:before {
      background-color: transparent;
    }
  }

  &.hasError {
    color: var(--color-error);
  }

  &:before {
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 50%;
    content: "";
    z-index: -1;
  }
}

.InputPasswordRequiredSign {
  color: var(--color-error);
  line-height: 1;
  margin-left: 4px;
}

.InputPasswordInput {
  padding: 0 16px;
  height: 46px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-gray);
  background: transparent;
  font-size: 14px;
  font-weight: 500;

  &.hasExtraPadding {
    padding-right: 64px;
  }

  &::placeholder {
    color: transparent;
  }

  &.noFloating {
    &::placeholder {
      color: var(--color-inverse-50);
      line-height: 20px;
      font-size: 14px;
    }
  }

  &.isReadOnly {
    color: var(--color-inverse-50);
  }

  &:placeholder-shown {
    ~ .InputPasswordLabel {
      top: 50%;
      font-size: 14px;
    }
  }

  &:focus {
    border: 1px solid var(--color-admin-primary);

    ~ .InputPasswordLabel {
      top: 0;
      font-size: 12px;
    }
  }

  &.isBorderless {
    border: none;
  }

  &.hasError {
    border: 1px solid var(--color-error);
  }

  &.hasLabel {
    &::placeholder {
      opacity: 0;
    }
  }

  &.infoText {
    padding-right: 40px;
  }
}

.InputPasswordPasswordEye {
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 11px;
  width: 24px;
  height: 24px;
}

.InputPasswordDefinition {
  position: absolute;
  right: 0;
  top: 8px;
  height: 30px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--color-gray);
}

.InputPasswordInfoText {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    .InputPasswordInfoTextTooltip {
      display: block;
    }
  }
}

.InputPasswordInfoTextTooltip {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-background-light-mode);
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 8px;
  width: 232px;
  overflow: auto;
}

.InputPasswordLabelTop {
  color: var(--color-text-white);
  margin-bottom: 10px;
}

@media (max-width: $mobile) {
  .InputPasswordInput {
    padding: 12px 14px;
  }
}
