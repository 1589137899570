@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.MascotDashboard {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  padding: 16px 16px 0 16px;
  background-color: var(--color-text-black);
  z-index: 100;

  .BallsBg {
    z-index: -1;
  }

  .MascotDashboardPaymentModal {
    .ModalContainer {
      width: 500px;
    }

    .ModalBody {
      padding: 0 16px;
    }
  }
}

.MascotDashboardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h3,
  p {
    color: var(--color-text-white);
  }
  .MascotDashboardLeftArea {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
  }
  .MascotDashboardGuestWrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--color-light-gray);
    cursor: pointer;
    margin-right: 8px;
  }

  .MascotDashboardGuestEditButton {
    background-color: var(--color-primary);
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-text-black);
    position: absolute;
    bottom: 0;
    right: 0;

    .EditButtonIcon {
      width: 12px;
      height: 12px;
    }
  }

  .MascotDashboardGuestImageContainer {
    width: 56px;
    height: 56px;

    .GuestProfileWithIconAvatar {
      height: 45px;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .MascotDashboardGuestName {
    margin-right: 8px;
    @include text-truncate-ellipsis(1);
    white-space: nowrap;
    word-break: break-all;
  }

  .MascotDashboardGuestDetailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    h4 {
      color: var(--color-text-white);
    }

    .MascotDashboardGuestZoneAndTable {
      color: var(--color-inverse-25);
      display: flex;
      align-items: center;
      width: 100%;
      h5 {
        color: var(--color-inverse-25);
      }

      h6 {
        color: var(--color-inverse-25);
        max-width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        position: relative;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  .MascotDashboardNotificationContainer {
    background-color: var(--color-mini-button-reverse);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
}

.MascotDashboardMain {
  height: calc(100% - 48px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  .MascotDashboardTitle {
    color: var(--color-text-white);
  }
}

.MascotDashboardMainTop > div {
  margin-top: 40px;
}

.MascotDashboardMainTop {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MascotDashboardLanguage {
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    margin-right: 10px;
  }

  .MascotDashboardLanguageNameContainer {
    h6,
    p {
      color: var(--color-text-white);
    }

    h6 {
      margin-bottom: 3px;
    }
  }
}

.CloseMascotDashboard {
  cursor: pointer;

  svg {
    width: 44px;
    height: 44px;

    path {
      stroke: var(--color-text-white);
    }
  }
}

.MascotCallButtonsContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  > div {
    width: 50%;
  }

  > div + div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .MascotCallButtonsContainerCallButton {
    display: flex;
    flex-direction: column;

    &.isDisabled {
      h6 {
        color: var(--color-inverse-50);
      }
    }

    .SwitchContainer {
      margin-top: auto;
    }
  }
}

.MascotEatInAndReserveButtons {
  display: flex;
  align-items: center;

  > button {
    width: 50%;
  }
}

.MascotDashboardSections {
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    > button {
      width: calc((100% / 2) - 8px);
      margin-bottom: 16px;
    }
  }
}

.MascotDashboardCopyRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  flex: 1;
  margin-top: 0;
  padding-bottom: 30px;

  .h7 {
    color: var(--color-text-white);
  }
}

.MascotDashboardFixProblem {
  h6,
  .h7 {
    color: var(--color-text-white);
  }

  .MascotDashboardFixProblemButton {
    margin-top: 8px;
    background: var(--color-text-black);
  }
}

.MascotDashboardLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  svg {
    width: 33px;
  }

  h4 {
    color: var(--color-text-white);
  }
}

.CustomDashboardButton {
  svg {
    path {
      stroke: var(--color-text-white);
    }
  }
}

.MascotDashboardConnectToWiFi {
  border-radius: 12px;
  background: var(--color-gradient-main);
  padding: 14px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media (max-width: 1000px) {
  .MascotDashboardUnderlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-text-black);
    z-index: 99;
    pointer-events: none;
  }
}

@media (max-width: $mobile) {
  .MascotCallButtonsContainer {
    .CallButton {
      min-height: 95px;
    }
  }
}
