.DashBoardInformationSection {
  margin: 16px 16px 0;
  .DashBoardInformationSectionGameArea {
    display: flex;
    gap: 8px;
    .DashBoardInformationSectionGameZone {
      cursor: pointer;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      overflow: hidden;
      border-radius: 12px;
      border: 2px solid #53838B;
      background: rgba(83, 131, 139, 0.20);
      backdrop-filter: blur(13px);
      width: 50%;




      &::before{
        content: '';
        position: absolute;
        width: 9px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 3px;
        background: #53838B;
      }

      &::before {
        left: -5px;
      }

      .DashBoardInformationSectionGameZoneContent {
        height: 100%;
        padding-left: 15px;
        width: 74%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 6px;
        padding-bottom: 16px;


        .DashBoardInformationSectionGameZoneContentTitle {
          color: var(--color-text-black);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      img{
        position: absolute;
        right: -23px;
        //top: -7px;
      }
    }
  }
}

.DashBoardGuestCount {
  padding-top: 16px;
  padding-left: 8px;
  margin-bottom: 8px;
  h6 {
    color: var(--color-text-black);
  }
}

.ProfilesAndOrders {
  margin-bottom: 40px;
  border-radius: 16px;
}
.DashBoardGuestInformation {
  display: flex;
  position: fixed;
  bottom: 25px;
  left: 16px;
  right: 16px;
  z-index: 15;
  align-items: center;
  gap: 16px;
  .GuestInformationPayButtonWrapper {
    justify-content: space-between;
    text-align: left;
    &:not(:disabled){
      background-color: var(--color-preferred-secondary-bg);
      &:active{
        scale: 0.95;
      }
      .GuestPayDetail{
        h3,h6{
          color: var(--color-preferred-secondary-text);
        }
      }
    }
  }
}

.MyOrders {
  padding-bottom: 56px;
}
.DashBoardChat {
  height: 400px;
  margin-top: 33px;
}

.OrderCard {
  width: calc((100% / 3) - 16px);
  margin: 8px;
}
