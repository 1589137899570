@import "src/assets/styles/mixins";
.MyProfile {
  padding:  16px;
  background-color: var(--color-gray-20);
  height: 100%;
}
.MyProfileHeader{
  display: flex;
  align-items: center;
  position: relative;
  .BackButton.typeD{
    background-color: var(--color-text-white);
  }
  h2{
    position: absolute;
    left: 50%;
    transform: translate(-50%);

  }
}
.MyProfileInfo {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background: var(--color-text-white);
  margin: 29px 0;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    path {
      stroke: #757575;
    }
  }

  .MyProfileGuestImageContainer {
    width: 64px;
    height: 64px;
    object-fit: cover;
    .GuestProfileWithIconAvatar {
      width: 50px;
      height: 50px;
    }
    .GuestProfileWithIconProfilePicture {
      width: 100%;
      height: 100%;
    }
  }

  .MyProfileInfoDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-left: 16px;
    h4,h5 {
      @include text-truncate-ellipsis(1);
      word-break: break-all;
    }
  }
}

.MyProfileGuestPhoneNumber {
  .react-international-phone-input {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.38);
  }
}
.GuestProfileOptions {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
}
.MyProfileContent {
  background-color: var(--color-text-white);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #eeedf2;
  padding: 0 12px;
  .LogOut {
    color: var(--color--semantic-error);
  }
}
.MyProfileContentSection {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  cursor: pointer;
  justify-content: center;
  svg {
    width: 28px;
    height: 28px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    h4{
      @include text-truncate-ellipsis(1);
      word-break: break-all;
    }
    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      bottom: -16px;
      background-color: #ebebeb;
      position: absolute;
    }
  }
  &:last-child div:before {
    content: none;
  }
}

.MyProfileEditGuestForm {
  width: 100%;
  padding: 32px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}