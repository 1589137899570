@import "src/assets/styles/variables";

$filterModalBodyBottomPaddingMobile: 98px;

.MenuFilterModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MenuFilterModalTagsScroll {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  scrollbar-width: none;
  margin-top: 24px;
  padding: 4px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.MenuFilterModalBody {
  padding-bottom: $filterModalBodyBottomPaddingMobile;
  min-height: 302px;
  min-width: 500px;

  > div + div {
    &.AccordionContainer {
      //border-top: 1px solid var(--color-stroke);
    }
  }

  .AccordionContainer {
    padding: 30px 0;

    .RangeComponentSlider {
      .tooltip {
        background-color: var(--color-preferred-primary-bg);
        color: var(--color-preferred-primary-text);

        &:after {
          border-top-color: var(--color-preferred-primary-bg);
        }
      }

      .range-slider__thumb {
        background-color: var(--color-preferred-primary-bg);
      }

      .range-slider__range {
        background-color: var(--color-preferred-primary-bg);
      }
    }

    &:first-child {
      padding-top: 0;
    }

    .AccordionContainerIconArrowContainer {
      display: none;
    }
  }

  @media (max-width: $tablet) {
    min-width: 400px;
  }

  @media (max-width: $mobile) {
    min-width: unset;
  }
}

.MenuFilterModalApplyButton {
  position: absolute;
  width: calc(100% - 32px);
  transition: bottom 0.3s ease-in-out;
  bottom: 30px;

  &:active {
    scale: 0.95;
  }

  .CTAButton {
    background-color: var(--color-preferred-primary-bg);

    h4 {
      color: var(--color-preferred-primary-text);
    }

    .ArrowIconContainer {
      svg {
        path {
          stroke: var(--color-preferred-primary-text);
        }
      }
    }

    &.typeE {
      &:active {
        background-color: var(--color-preferred-primary-bg);

        h4 {
          color: var(--color-preferred-primary-text);
        }

        .ArrowIconContainer {
          svg {
            path {
              stroke: var(--color-preferred-primary-text);
            }
          }
        }
      }
    }
  }
}
