.ContainerClassname {
  .DeleteItemModalHeader {
    .CloseButtonContainer {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .DeleteItemModalBodyIconWrapper {
      display: flex;
      justify-content: center;
      padding: 16px 0;

      .DeleteItemModalBodyIcon {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .DeleteItemModalBodyExclamationIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .DeleteItemModalBody {
    padding: 0 16px;
    overflow: auto;


    .DeleteItemModalTitle {
      color: var(--color-text-white);
      margin-bottom: 40px;
      text-align: center;
    }
    .DeleteItemModalBodyContainer{
      display: flex;
      flex-direction: column;
      gap: 40px;
      overflow: auto;
      max-height: 200px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
      .DeleteMenuItemsModalBodyContentDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &::-webkit-scrollbar {
          display: none;
        }
        .DeleteMenuItemsModalBodyContentDetail {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;

          .DeleteMenuItemsModalBodyContentDetailContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .DeleteMenuItemsModalBodyContentDetailContentTitle {
              overflow: hidden;
              color: var(--color-text-white);
              text-overflow: ellipsis;
            }

            .DeleteMenuItemsModalBodyContentDetailContentTotalPriceWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;

              .DeleteMenuItemsModalBodyContentTotalPrice {
                overflow: hidden;
                color: var(--color-text-white);
                text-overflow: ellipsis;

                .SemiBold {
                  color: var(--color-text-white);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                }

                .Medium {
                  color: var(--color-inverse-25);
                }
              }
            }
          }

          .DeleteMenuItemsModalBodyContentDetailContentModifications {
            display: flex;
            padding-left: 8px;
            flex-direction: column;
            align-items: flex-start;

            .DeleteMenuItemsModalBodyContentDetailContentModificationWrapper {
              display: flex;
              gap: 5px;
              flex-wrap: wrap;

              .DeleteMenuItemsModalBodyContentDetailContentModification {
                overflow: hidden;
                color: var(--color-inverse-25);
                text-overflow: ellipsis;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .SemiBold {
                  color: var(--color-inverse-25);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }
      .DeleteMenuItemsModalBodyContentConfirmedDetails {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        position: relative;
        .DeleteMenuItemsModalBodyContentConfirmedDetailsTitle{
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: center;
          padding-bottom: 16px;
          .DeleteMenuItemsModalBodyContentConfirmedDetailsTitleIcon{
           width: 32px;
            height: 32px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .DeleteItemModalBodyExclamationIcon{
              position: absolute;
              left: 11px;
              width: 11px;
              height: 11px;
              top: 12px;
              path{
                stroke: var(--color-primary);
              }
            }
          }
        }

        h6{
          color: var(--color-text-white);
        }

        &::-webkit-scrollbar {
          display: none;
        }
        .DeleteMenuItemsModalBodyContentConfirmedDetail {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;

          .DeleteMenuItemsModalBodyContentConfirmedDetailContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .DeleteMenuItemsModalBodyContentConfirmedDetailContentTitle {
              overflow: hidden;
              color: var(--color-inverse-25);
              text-overflow: ellipsis;
            }

            .DeleteMenuItemsModalBodyContentConfirmedDetailContentTotalPriceWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;

              .DeleteMenuItemsModalBodyContentConfirmedTotalPrice {
                overflow: hidden;
                color: var(--color-inverse-25);
                text-overflow: ellipsis;

                .SemiBold {
                  color: var(--color-inverse-25);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                }

                .Medium {
                  color: var(--color-inverse-25);
                }
              }
            }
          }

          .DeleteMenuItemsModalBodyContentConfirmedDetailContentModifications {
            display: flex;
            padding-left: 8px;
            flex-direction: column;
            align-items: flex-start;

            .DeleteMenuItemsModalBodyContentConfirmedDetailContentModificationWrapper {
              display: flex;
              gap: 5px;
              flex-wrap: wrap;

              .DeleteMenuItemsModalBodyContentConfirmedDetailContentModification {
                overflow: hidden;
                color: var(--color-inverse-25);
                text-overflow: ellipsis;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .SemiBold {
                  color: var(--color-inverse-25);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }

    }

  }

  .DeleteItemModalFooter {
    padding: 16px;
    margin-top: 24px;
  }
  .DeleteMenuItemsModalBodyContentDetailsLine{
    border-bottom: 1px solid var(--color-inverse-50);
  }
}
