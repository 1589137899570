.StepContent {
  margin-top: 56px;
  transition: transform 0.5s ease;
}

.slideFromLeft {
  animation: slideFromLeft 0.5s ease;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
