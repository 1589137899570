.TicTacToeGame{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #2C3E50, #4CA1AF);
  flex-direction: column;
  padding:  16px;
}
.TicTacToeGameHeader{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .TicTacToeGameHeaderBackRefreshButton{
    width: 40px;
    height: 40px;
    background: #1A202C;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
  }
  h3{
    color: var(--color-text-white);
  }
  .TicTacToeGameTurnBox{
    display: flex;
    align-items: center;

  }


}
.TicTacToeGameBody{
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(3,1fr);
  height: 50%;
  width: 100%;
  gap: 4px;
  .TicTacToeGameBodyBox{
    color: var(--color-text-white);
    font-size: 50px;
    cursor: pointer;
    font-weight: 700;
    background: #1A202C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    &.isWinnerBox{
      background-color: rgb(8, 217, 214) !important;
      color: var(--color-text-black);
    }
    &.isDisabled{
      cursor: not-allowed;
      pointer-events: none;
    }
    //&:hover{
    //  background: #FF914D;
    //
    //}
  }
}
.TicTacToeGameScoreBoards{
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 50px;
  .TicTacToeGameScoreBoard{
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background: #1A202C;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    &.isActive{
      background-color: #08D9D6;
    }
    h2,h5{
      color: var(--color-text-white);
    }
    div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }

  }
}
.TicTacToeGameFooter{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  h2{
    color: var(--color-text-white);
  }
  button{
    border-radius: 5px;
    padding: 10px 25px;
    background-color: #FF2E63;
    color: var(--color-text-white);
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
}

.TicTacToeGameTurn{
  color: var(--color-text-white);
  margin-top: 44px;
  height: 24px;
}