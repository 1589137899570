.GuestProfileWithIconContainer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.GuestProfileWithIconProfilePicture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;

}

.GuestProfileWithIconProfilePictureSpinner {
  .Spinner {
    border: 5px solid var(--color-preferred-secondary-bg);
    border-top: 5px solid var(--color-text-white);
  }
}

.GuestProfileWithIconAvatar {
  width: 60px;
  height: 60px;
  position: absolute;
  object-fit: cover;
  bottom: 0;
}

.GuestProfileWithIconActionButtonWrapper {
  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;

    &:active {
      scale: 0.95;
    }
  }
}
