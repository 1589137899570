@import "src/assets/styles/_variables";

.RangeComponentSlider {
  .RangeComponentSliderWrap {
    padding: 80px 10px 0;
    position: relative;
  }
  .range-slider {
    height: 3px;
    background: var(--color-stroke);

    .range-slider__range {
      background: var(--color-inverse-100);
    }
  }
  .range-slider__thumb {
    width: 10px;
    height: 10px;
    background: var(--color-inverse-100);
  }

  .tooltip {
    position: absolute;
    top: -10px;
    left: 50%;
    background: var(--color-inverse-100);
    border-radius: 50px;
    width: 58px;
    height: 58px;
    color: var(--color-text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -100%);

    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid var(--color-inverse-100);
    }
  }
}

.RangeComponentBordersWrapper {
  padding: 0 18px;
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div.h8 {
    font-weight: 500;
    color: var(--color-inverse-75);
  }
}

.RangeBordersContainer {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .RangeBordersMin,
  .RangeBordersMax {
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    background-color: var(--color-mini-button);
    padding: 13px 20px;
    gap: 24px;
    flex-grow: 1;

    span {
      color: var(--color-inverse-50);
      font-size: 10px;
      font-weight: 500;
    }
  }
}
