.AdminQr {
  padding: 30px;

  h2 {
    color: var(--color-text-black);
  }

  .AdminQrCaption {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;

    .AdminQrCaptionButtons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .AllQrDownloadButton {
        font-size: 16px;
        color: var(--color-text-black);
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 16px;
        display: flex;
        gap: 8px;

        svg {
          margin: 0;
        }
      }

      .AddNewButton {
        white-space: nowrap;
      }
    }
  }

  .AdminQrTitle {
    flex: 1;
    font-weight: 800;
    opacity: 0.7;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.AdminQrContainer {
  height: calc(100vh - 155px);
}

.AdminQrContainerTable {
  padding-bottom: 30px;

  .TableHeader .TableColumnItemClass {
    margin-right: initial;
  }

  .TableDataList .TableCustomRowItem {
    margin-right: initial;
  }
}

.AdminQrGoBack {
  margin-right: 12px;
  cursor: pointer;
}

.AdminQrStatus {
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: max-content;
  padding: 0 12px;

  &.linked {
    background: var(--color-green-15);
    color: var(--color-success);
  }

  &.unlinked {
    background: var(--color-red-15);
    color: var(--color-error);
  }
}