@import "src/assets/styles/variables";

.AdminController {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  &.isMobile {
    overflow-y: auto;
  }

  &.isDesktop {
    display: flex;
  }
}
