.ContainerClassname {
  .ReceiptModalHeader {
    .CloseButtonContainer {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .ReceiptModalBodyIconWrapper {
      display: flex;
      justify-content: center;
      padding: 16px 0;

      .ReceiptModalBodyIcon {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .ReceiptModalBodyExclamationIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .ReceiptModalBody {
    padding: 0 16px;
    overflow: auto;


    .ReceiptModalTitle {
      color: var(--color-text-white);
      margin-bottom: 40px;
      text-align: center;
    }
    .ReceiptModalBodyContainer{
      display: flex;
      flex-direction: column;
      gap: 32px;
      overflow: auto;
      max-height: 200px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
      .ReceiptModalBodyContentDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &::-webkit-scrollbar {
          display: none;
        }
        .ReceiptModalBodyContentDetail {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;

          .ReceiptModalBodyContentDetailContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ReceiptModalBodyContentDetailContentTitle {
              overflow: hidden;
              color: var(--color-text-white);
              text-overflow: ellipsis;
            }

            .ReceiptModalBodyContentDetailContentTotalPriceWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;

              .ReceiptModalBodyContentTotalPrice {
                overflow: hidden;
                color: var(--color-text-white);
                text-overflow: ellipsis;

                .SemiBold {
                  color: var(--color-text-white);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                }

                .Medium {
                  color: var(--color-inverse-25);
                }
              }
            }
          }

          .ReceiptModalBodyContentDetailContentModifications {
            display: flex;
            padding-left: 8px;
            flex-direction: column;
            align-items: flex-start;

            .ReceiptModalBodyContentDetailContentModificationWrapper {
              display: flex;
              gap: 5px;
              flex-wrap: wrap;

              .ReceiptModalBodyContentDetailContentModification {
                overflow: hidden;
                color: var(--color-inverse-25);
                text-overflow: ellipsis;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .SemiBold {
                  color: var(--color-inverse-25);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }
      .ReceiptModalBodyContentConfirmedDetails {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        position: relative;
        .ReceiptModalBodyContentConfirmedDetailsTitle{
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: center;
          padding-bottom: 16px;
          .ReceiptModalBodyContentConfirmedDetailsTitleIcon{
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .ReceiptModalBodyExclamationIcon{
              position: absolute;
              left: 11px;
              width: 11px;
              height: 11px;
              top: 12px;
              path{
                fill: var(--color-primary);
              }
            }
          }
        }

        h6{
          color: var(--color-text-white);
        }

        &::-webkit-scrollbar {
          display: none;
        }
        .ReceiptModalBodyContentConfirmedDetail {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;

          .ReceiptModalBodyContentConfirmedDetailContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ReceiptModalBodyContentConfirmedDetailContentTitle {
              overflow: hidden;
              color: var(--color-dark-mode-error);
              text-overflow: ellipsis;
            }

            .ReceiptModalBodyContentConfirmedDetailContentTotalPriceWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;

              .ReceiptModalBodyContentConfirmedTotalPrice {
                overflow: hidden;
                color: var(--color-dark-mode-error);
                text-overflow: ellipsis;

                .SemiBold {
                  color: var(--color-dark-mode-error);

                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                }

                .Medium {
                  color: var(--color-inverse-25);
                }
              }
            }
          }

          .ReceiptModalBodyContentConfirmedDetailContentModifications {
            display: flex;
            padding-left: 8px;
            flex-direction: column;
            align-items: flex-start;

            .ReceiptModalBodyContentConfirmedDetailContentModificationWrapper {
              display: flex;
              gap: 5px;
              flex-wrap: wrap;

              .ReceiptModalBodyContentConfirmedDetailContentModification {
                overflow: hidden;
                color: var(--color-inverse-25);
                text-overflow: ellipsis;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .SemiBold {
                  color: var(--color-inverse-25);
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }

    }

  }
  .ReceiptModalBodyOrderInfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
    .ReceiptModalBodyOrderInfoDetail{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .TotalPrice,{
        h6{
          color: var(--color-success);

        }
      }
      .TotalLabel{
        color: var(--color-success);
      }
      h6{
        color: var(--color-text-white);
        display: flex;
        align-items: center;
        span{
          color: var(--color-text-white);
        }
      }
    }
  }

  .ReceiptModalFooter {
    padding: 16px;
    margin-top: 24px;
    .CTAButton{
      &:disabled{
        background-color: var(--color-inverse-75);
        .Price{
          h4{
            color: var(--color-inverse-25);
          }
        }
      }
    }
  }
  .ReceiptModalBodyContentDetailsLine{
    border-bottom: 1px solid var(--color-inverse-50);
  }
}
.ReceiptModalFooterPayButton{
  justify-content: space-between;
  text-align: left;
  &:disabled{
    .GuestPayDetail{
      h3,h6{
        color: var(--color-inverse-25);
      }
    }
  }
  &.isLoading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    svg{

      stroke: var(--color-preferred-secondary-text);
    }
  }
  &:not(:disabled){
    background-color: var(--color-preferred-secondary-bg);
    &:active{
      scale: 0.95;
    }
    .GuestPayDetail{
      h3,h6{
        color: var(--color-preferred-secondary-text);
      }
    }
  }
}
