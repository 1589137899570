p {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
}

h1 {
  font-size: 26px;
  line-height: 32px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
}

h6 {
  font-size: 14px;
  line-height: 16px;
}

.h7 {
  font-size: 12px;
  line-height: 16px;
}

.h8 {
  font-size: 10px;
  line-height: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.ExtraLight {
  font-weight: 200;
}

.Light {
  font-weight: 300;
}

.Regular {
  font-weight: 400;
}


.Medium {
  font-weight: 500;
}

.SemiBold {
  font-weight: 600;
}

.Bold {
  font-weight: 700;
}

.LetterSpacingS {
  letter-spacing: -1;
}
