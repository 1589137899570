.SpeechToTextContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, #1c1d21 0%, #272b2e 100%);

  .BackButton {
    width: 44px;
    height: 44px;
    background: var(
      --Light-Mode-Text-Black,
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      #1f1f1f
    );

    border-radius: 8px;
    display: flex;
    align-items: center;
    svg {
      path {
        fill: var(--color-text-white);
      }
    }
  }
  .SpeechToTextContainerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .SpeechToTextContainerLeft{
      display: flex;
      gap: 12px;
      align-items: center;
    }
    .SpeechToTextContainerHeaderTitle {
      color: var(--color-text-white);
    }
    .SpeechToTextContainerHeaderActions {
      display: flex;
      align-items: center;
      gap: 12px;
      .TrashContainer {
        cursor: pointer;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 3px;
        border-radius: 8px;
        background: var(
          --Light-Mode-Text-Black,
          linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          #1f1f1f
        );
      }
    }
    .BasketContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      padding: 7px 3px;
      border-radius: 8px;
      background: var(
        --Light-Mode-Text-Black,
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #1f1f1f
      );
      .BasketButtonCount {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 10px;
        top: -8px;
        left: -11px;
        background: var(--color-preferred-secondary-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;

        h6 {
          color: var(--color-preferred-secondary-text);
        }
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  .SpeechToTextContainerContentEmptyState {
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: 0.1;
      width: 72px;
      height: 50px;
    }
  }

  .SpeechToTextContainerContent {
    height: calc(100% - 151px);
    padding: 0 16px;
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-items: flex-end;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .SpeechToTextContainerMessage {
      padding: 16px;
      border-radius: 16px;
      background: #32383e;
      width: fit-content;
      max-width: 300px;
      &:first-child{
        margin-top: 17px;
      }
      h5 {
        color: var(--color-text-white);
        word-break: break-word;
      }
    }
    .SpeechToTextContainerResponseMessage {
      padding: 16px;
      border-radius: 16px;
      background: var(
        --Light-Mode-Text-Black,
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #1f1f1f
      );
      width: max-content;
      max-width: 90%;
      align-self: flex-start;
      h5 {
        color: var(--color-text-white);
      }
    }
  }
  .SpeechToTextContainerFooter {
    position: relative;
    padding: 16px;
    width: 100%;
    .ModalContainer{
      width:167px ;
      min-height: 195px;
      position: absolute;
      bottom: 158px;
      .Modal{
       padding-top: 40px;
        background-color: #32383E;

      }
      .ModalHeader{
        padding:8px;
        height: 24px;
        margin-top: 8px;
        background-color: #32383E;

      }
      .ModalBody{
        padding: 8px;
      }
      .ModalFooter{
        height: auto;
        background-color: #32383E;

      }
    }
    .ChatFooterFormInput {
      .InputControlInput {
        border-radius: 26px;
        background: #32383e;
        height: 56px;
        padding-left: 55px;
        color: var(--color-text-white);

        border: none;
      }
      .InputControlLabel {
        color: var(--color-inverse-50);
      }
    }
  }
  .Recommends {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    min-height: 80px;
    .Recommend {
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;
      background: var(
        --Light-Mode-Text-Black,
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #1f1f1f
      );
      min-width: 180px;
      width: 100%;
      height: 76px;
      max-width: 196px;

      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
      h5 {
        color: var(--color-text-white);
      }
    }
  }
}
