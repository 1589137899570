@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.MenuHeroSection {
  padding: 20px 16px 0;
  background-color: var(--color-preferred-primary-bg);
  width: 100%;

  .MenuHeroSectionServiceFee {
    color: var(--color-preferred-primary-text);
  }

  .RestaurantName {
    color: var(--color-preferred-primary-text);
  }

  .HeroHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .FilterAndHamburgerMenu {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .FilterChanges {
        width: 12px;
        height: 12px;
        border-radius: 8px;
        background: #fd0;
        position: absolute;
        top: 0;
        left: 0;
      }

      .FilterIcon {
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          path {
            stroke: var(--color-preferred-primary-text);
          }
        }
      }

      > div + div {
        margin-left: 8px;
      }
    }
  }
}

.MenuViewSelectionWrapper {
  position: relative;
  bottom: -1px;
}

.MenuHeroSectionBusinessInfo {
  display: flex;
  align-items: center;

  .MenuHeroSectionBusinessDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    margin-right: 8px;
    color: var(--color-preferred-primary-text);

    .RestaurantName {
      @include text-truncate-ellipsis(2);
      word-break: break-word;
    }
  }
  .LogoParent {
    $logoSize: 50px;
    $logoGap: 10px;
    position: relative;
    min-width: $logoSize;
    height: $logoSize;
    margin-right: 8px;

    .LogoOuterCircle {
      position: absolute;
      width: $logoSize;
      height: $logoSize;
      border-radius: 50%;
      inset: 0;
      background: linear-gradient(45deg, #ffcc16 0%, #ff2a2a 25%, #da02bc 50%, #ff2a2a 75%, #ffcc16 100%);
      padding: 3px;
      animation: rotateInCircle 1.5s ease-in-out infinite;
    }
    @keyframes rotateInCircle {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .Logo {
      position: absolute;
      top: calc($logoGap / 2);
      left: calc($logoGap / 2);
      width: calc($logoSize - $logoGap);
      height: calc($logoSize - $logoGap);
      border-radius: 50%;
      box-shadow: 0 0 0 2.9px var(--color-preferred-primary-bg);
      object-fit: cover;
      z-index: 1;
    }
  }
}
