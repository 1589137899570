.FeedBackHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
  h2 {
    color: var(--color-text-white);
  }
}

.FeedBackModalBody {
  min-height: fit-content;
}
.FeedbackModalContainer {
  background-color: var(--color-mini-button-reverse);
  padding: 16px 16px 10px;
}
