.AdminMapView{
  width: 100%;
  height: 100%;

}
.AdminMapViewContainer{
  border-color: var(--color-inverse-25);
  border-style: solid;
  border-radius: 16px;
  margin: 24px auto;
}
.AdminMapViewContent{
  background-color: var(--color-text-white);
  border-radius: 6px;
  overflow: hidden;
}
