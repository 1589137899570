@import "src/assets/styles/mixins";

.BasketItem {
  display: flex;
  padding: 5px;
  background-color: var(--color-background-light-mode);
  border-radius: 8px;
  border: 1px solid var(--color-stroke);
  gap: 13px;
  position: relative;
  cursor: pointer;
  max-height: 146px;

  &.isOpened {
    max-height: 1000px;

    > img {
      max-height: 1000px;
    }
  }

  > div {
    flex-grow: 1;
  }

  > img {
    max-width: 114px;
    min-width: 114px;
    max-height: 133px;
    min-height: 133px;
    border-radius: 8px;
    object-fit: cover;
    transition: 0.3s;
  }

  .DetailsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 13px;
    min-height: 133px;
  }

  .PriceContainer {
    color: var(--color-text-black);
    margin-top: 7px;
    margin-bottom: 20px;
    display: flex;
    gap: 5px;

    h6 {
      line-height: 12px;
    }
  }

  .priceDiscounted {
    margin-left: 5px;
    color: var(--color-inverse-25);
    text-decoration: line-through;
    font-weight: 500;
  }

  .ProductDetail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 0;
    height: 100%;
    justify-content: space-between;

    .ProductDetailTitle {
      line-height: 17px;
      color: var(--color-background-dark-mode);
      margin-bottom: 5px;
      @include text-truncate-ellipsis(1);
      word-break: break-word;
    }

    .ProductDetailOrderType {
      color: var(--color-inverse-50);
      @include text-truncate-ellipsis(2);
      word-break: break-word;
    }
  }

  .ProductDetailsList {
    margin: 0;
    overflow: hidden;
    max-height: 0;
    max-width: 100%;
    height: 0;
    opacity: 0;
    transition: 0.3s;

    li {
      text-indent: -6px;
      margin-left: 15px;
      font-size: 10px;
      line-height: 17px;
      color: var(--color-inverse-50);

      &:first-child {
        padding-top: 10px;
      }

      .ProductDetailOneList {
        color: var(--color-inverse-50);
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }

    &.openList {
      margin: 10px 0 20px;
      max-height: 57px;
      height: auto;
      overflow-y: auto;
      opacity: 1;
      position: relative;

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        background: var(--color-stroke);
        position: absolute;
      }

      &::-webkit-scrollbar {
        width: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }

  .ShowMoreDetails {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    flex-direction: column;
  }

  .ShowMoreBtn {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px;
    outline: none;
    background-color: var(--color-mini-button);
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    &.hasNoSelectedModifications {
      opacity: 0;
      pointer-events: none;
    }

    img {
      &.rotateArrow {
        transform: rotate(180deg);
      }
    }

    &:active {
      background-color: var(--color-stroke);
    }
  }
}
