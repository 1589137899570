.BestChoiceModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 20px;

  h1 {
    color: var(--color-text-white);
  }

  .BestChoiceModalHeaderIcons {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

  }
}

.BestChoiceModalBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 50px;
  height: 100%;
  width: 100%;

  .AddToBasketButton {
    height: 43px;
    width: 100%;
    margin-top: 16px;
  }
}

.ResultItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  word-break: break-word;

  h5 {
    color: var(--color-inverse-25);
    margin-bottom: 16px;
  }

  .ResultItemTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    h3 {
      color: var(--color-text-white);
    }
  }
}

.AddedToCart {
  color: var(--color-success);
  margin-top: 16px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.ResultItemDetails {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    visibility: hidden;
  }
}
