@import "src/assets/styles/variables";

.Rating {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.RatingEmoji {
  display: flex;
  gap: 12px;
  > svg {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.RatingEmojiItem {
  img {
    width: 40px;
  }
  &.isFilled {
    scale: 1.2;
  }
}
@media (max-width: $mobile) {
  .RatingEmoji {
    gap: 6px;
  }
}
