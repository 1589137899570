@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.MenuViewList {
  height: fit-content;
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 8px;

  cursor: pointer;
  border-bottom: 1px solid var(--color-stroke);
  padding-bottom: 16px;
}

.MenuViewListLeft {
  width: calc(100% - 136px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.MenuViewListRight {
  position: relative;
  border-radius: var(--radius-preferred-border-radiusA);
  width: 144px;
  border: 1px solid var(--color-stroke);
  overflow: hidden;
  height: 160px;
}

.MenuViewListImg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.MenuViewListFavoritesButton {
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 4;
}

.MenuViewListAddButton {
  position: absolute;
  right: -1px;
  bottom: -1px;
  z-index: 4;
}

.MenuViewListPrice {
  display: flex;
  align-items: center;
  gap: 8px;
}

.MenuViewListName {
  margin-bottom: 4px;
  @include text-truncate-ellipsis();
}
.MenuViewListTagsWithIcon {
  display: flex;
  gap: 8px;
  margin: 8px 0;
  overflow: auto;
  scrollbar-width: none;
}
.MenuViewListDescription {
  color: var(--color-inverse-75);
  @include text-truncate-ellipsis();
  margin-bottom: auto;
  padding-top: 8px;
}
