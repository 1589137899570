@import 'src/assets/styles/_variables';

.ThreeDots {
  position: relative;
}

.ThreeDotsDropdown {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  width: 160px;
  border-radius: 6px;
  background: var(--color-background-light-mode);
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.ThreeDotsDropdownItem {
  padding: 8px 14px;
  transition: $defaultTransition;
  cursor: pointer;

  &:hover {
    background-color: var(--color-mini-button);
  }
}

.ThreeDotsButton {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);

  .ThreeDotsButtonIcon {
    display: flex;
    &:hover {
      background-color: var(--color-group-background-light-mode);
    }
  }
}
