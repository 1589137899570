.ReservationContactForm{
  margin-top: 40px;
}
.ContactForm{
  margin-bottom: 100px;
  margin-top: 32px;

  .InputControlContainer{
    margin-bottom: 16px;
  }

}

