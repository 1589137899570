@import "src/assets/styles/_variables";

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    bottom: -100%;
  }
  100% {
    transform: translateY(0);
    bottom: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
    bottom: 0;
  }
  100% {
    transform: translateY(100%);
    bottom: -100%;
  }
}

.MenuItemSelectedContainerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;

  &.slideDownOverlay{
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.MenuItemSelected {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  max-height: 90dvh;
  min-height: 40dvh;
  bottom: 0;
  width: 100%;
  z-index: 10;
  //background: #fff;
  border-radius: 20px 20px 0 0;
  // Apply animation
  animation: slideUp 0.5s ease-out forwards;

  >div:not(.MenuItemHeaderContainer){
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.hide{
    animation: slideDown 0.5s ease-out forwards;
  }

  .MenuItemHeaderContainerDefault {
    position: sticky;
    top: 0;
    padding: 16px;
    transition: background-color 0.1s ease-in-out;
  }
  .MenuItemHeaderContainerSliderCatch {
    position: sticky;
    background: #fff;
    padding: 16px;
    top: 0;
    transition: background-color 0.1s ease-in-out;
  }
  .MenuItemCoverImage {
    position: relative;

  }
  .MenuItemCoverImage,
  .MenuItemCarouselImage {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }

  .MenuItemModifications {
    background: var(--color-mini-button);
    padding: 16px 16px 32px;

    > * + * {
      margin-top: 16px;
    }
  }

  .MenuItemFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: rgba(255, 255, 255, 0.65);
    position: sticky;
    backdrop-filter: blur(4px);
    width: 100%;
    padding: 12px 16px 30px 16px;
    z-index: 10;
    bottom: 0;
    height: 20%;

    .AnimatedPlusMinusButtonContainer {
      flex: 1;
      position: sticky;
    }

    .CTAButton {
      flex: 3;
      bottom: 0;
      height: 56px;
    }
  }

  .AddToBasketBtn,
  .SaveChanges {
    &:active {
      scale: 0.95;
    }
  }

  .MenuItemInfoContainer {
    padding: 16px 16px 0;
    min-height: 170px;

    h2 {
      margin-bottom: 16px;
    }
  }

  .MenuItemSlider {
    height: 420px;
    z-index: -1;

    .SwiperSlide {
      height: 100%;
    }
  }

  //> div {
  //  &:nth-last-child(2) {
  //    margin-bottom: 80px;
  //  }
  //}

  .MenuItemCoverPlaceHolderEmptyArea {
    width: 100%;
    height: 76px;
    background: #fff;
  }
}

//.MenuItemSelected.hide {
//  // Slide-down animation for hiding
//  animation: slideDown 0.5s ease-out forwards;
//}
