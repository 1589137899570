@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.MenuViewSelection {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MenuViewSelectionOption {
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  width: 85px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    path {
      fill: var(--color-preferred-primary-text);
    }

    rect {
      stroke: var(--color-preferred-primary-text);
    }
  }

  &.isActive {
    background: var(--color-text-white);
    //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25) inset;
    &::before {
      @include cornerShadow("left", 8px, 8px, 8px, var(--color-text-white));
    }

    &::after {
      @include cornerShadow("right", 8px, -8px, 8px, var(--color-text-white));
    }

    .MenuViewSelectionOptionName {
      color: var(--color-text-black);
    }

    svg {
      path {
        fill: var(--color-text-black);
      }
      rect {
        stroke: var(--color-text-black);
      }
    }
  }
}

.MenuViewSelectionOptionName {
  color: var(--color-preferred-primary-text);
  margin-top: 4px;
}
