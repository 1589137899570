.MethodSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 5px 5px;
  width: 100%;
  border: 1px solid var(--color-stroke);
  border-radius: 10px;
  cursor: pointer;
}

.MethodSelectionIcon {
  border-radius: 10px;
  background: var(--color-mini-button);
  margin-right: 10px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.MethodSelectionName {
  flex-grow: 1;
}
