@import "src/assets/styles/variables";

.LandingPage {
  .LandingPageHeader {
    background-color: var(--color-text-black);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .LandingPageLanguageDropDownAndSignIn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 250px;

      .LandingPageSignInButton {
        background-color: var(--color-background-light-mode);
        padding: 12px 24px;
        width: auto;
        min-width: 110px;
        border-radius: 40px;
      }
    }
    .DropdownSelectedItem {
      border: none;
      .DropdownArrow {
        filter: invert(1) brightness(1000%);
      }
      .DropdownSelectedItemTitle {
        .DropdownSelectedItemTitleImage {
          border-radius: 4px;
          width: 32px;
        }
        h6 {
          color: var(--color-text-white);
        }
      }
    }
    .Search {
      width: 100%;
      max-width: none;
      margin-top: 56px;
    }
  }
  .LandingPageHeaderTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    h4 {
      color: var(--color-text-white);
    }
  }
  .LandingPageImage {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 900px;
      object-fit: contain;
    }
  }
  .FooterContainer {
    margin-top: 40px;
  }
}

@media (max-width: $mobile) {
  .LandingPage {
    .LandingPageHeader {
      .LandingPageHeaderTitle {
        svg {
          width: 32px;
          height: 32px;
        }
      }
      .LandingPageLanguageDropDownAndSignIn {
        width: 218px;
      }

      .LandingPageSignInButton {
        padding: 12px 16px;
      }
      .DropdownSelectedItemTitle {
        h6 {
          display: none;
        }
      }
    }
  }
}
