@import "src/assets/styles/_variables";

.AdminBusiness {
  padding: 30px;
}

.AdminBusinessCaptionSaveButton {
  width: auto;
  padding: 12px 16px;
}

.AdminBusinessInfoAboutFormGroupTitle {
  color: var(--color-inverse-75);
  margin-bottom: 16px;
}

.AdminBusinessInfoAboutForm {
  display: flex;
}

.AdminBusinessInfoAboutFormPart {
  width: 50%;
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 40px;
  }

  &:first-child {
    border-right: 2px solid var(--color-group-background-light-mode);
    padding-right: 42px;
  }

  &:nth-child(2) {
    padding-left: 42px;
  }
}

.AdminBusinessInfoAboutFormGroup {
  .InputGroups {
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    * {
      .InputControlLabel {
        &:before {
          background-color: var(--color-background-light-mode);
        }
      }

      .DropdownLabel {
        background-color: var(--color-background-light-mode);
        color: var(--color-inverse-50);
      }
    }

    .InputControlDefinition {
      font-weight: 600;
    }

    > div {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .InputGroupsMini {
    display: flex;

    .InputControlContainer {
      &:nth-child(1) {
        flex: 3;
        margin-right: 10px;
      }

      &:nth-child(2) {
        flex: 1;
      }
    }
  }
}

.AdminBusinessInfoAboutFormGroupInline {
  .AdminBusinessInfoAboutImagesLogoBackground {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;

    .AdminBusinessInfoAboutImagesLogoAdd,
    .AdminBusinessInfoAboutImagesBackgroundAdd {
      width: 250px;
      height: 250px;

      @media (max-width: 1500px) {
        width: 190px;
        height: 190px;
      }
      @media (max-width: $desktop) {
        width: 120px;
        height: 120px;
      }
      @media (max-width: $tablet) {
        width: 90px;
        height: 90px;
      }
    }
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.AdminBusinessInfoAboutImagesLogo {
  margin-right: 35px;
}

.AdminBusinessInfoAboutImagesOther {
  width: 100%;
  margin-top: 20px;

  .AddPhotoUploadedImages {
    width: 100%;

    .AddPhotoUploadedImagesList {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: flex-start;

      > div {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
      }
    }
  }
}

.AdminBusinessInfoAboutImagesTitle {
  margin-bottom: 10px;
  color: var(--color-inverse-75);
  white-space: nowrap;
}

.AdminBusinessInfoContactData {
  > div {
    border-color: var(--color-gray);

    .ContactIcon {
      border-radius: 8px 0 0 8px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.AdminBusinessInfoAboutPrintersTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.AdminBusinessInfoAboutPrintersListMessage {
  color: var(--color-inverse-50);
}

.AdminBusinessInfoAboutPrinters {
  .Tag {
    cursor: pointer;
  }
}

@media (max-width: $mobile) {
  .AdminBusiness {
    padding: 26px 16px 60px 16px;

    .PrimaryButton.typeA {
      position: fixed;
      bottom: 30px;
      left: 16px;
      width: calc(100% - 32px);
      z-index: 4;
    }
  }

  .AdminBusinessInfoAboutForm {
    flex-direction: column;
  }

  .AdminBusinessInfoAboutFormPart {
    width: 100%;

    > div {
      margin-bottom: 56px;
    }
  }

  .AdminBusinessInfoAboutFormPart {
    &:first-child,
    &:nth-child(2) {
      border: none;
      padding: 0;
    }
  }
}

.AdminBusinessMapInput {
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  display: flex;
  align-items: center;

  &.hasError {
    border: 1px solid var(--color-error);
  }

  .MapIcon {
    position: relative;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      position: absolute;
      right: 0;
      width: 1px;
      top: 5px;
      bottom: 5px;
      content: "";
      background-color: var(--color-gray);
    }
  }

  .InputControlContainer {
    width: 100%;
  }

  input {
    border: none;
    width: 100%;

    &:focus {
      border: none;
    }
  }
}
