@import "src/assets/styles/variables";

.Confirm {
  cursor: default;

  &.typeA {
    .ConfirmModalBodyIcon {
      background-color: var(--color-red-15);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .ConfirmModalBodyIconTriangle {
      display: none;
    }
  }
  &.typeB {
    .ConfirmModalBodyIcon {
      position: relative;
      svg {
        path {
          fill: var(--color-admin-primary);
        }
      }
    }

    .ConfirmModalBodyIconTriangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ConfirmModalBodyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    margin-bottom: 24px;
    text-align: center;
    word-break: break-word;
  }

  h6 {
    margin-bottom: 30px;
    color: var(--color-inverse-50);
  }
}

.ConfirmModalBodyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto 40px;
}

.ConfirmModalBody {
  width: 500px;

  @media (max-width: $mobile) {
    width: 100%;
  }
}
