@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

.FavoriteCard {
  position: relative;
  background: var(--color-background-light-mode);
  padding: 5px;
  border-radius: 8px;
  border: 1px solid var(--color-stroke);
  overflow: hidden;
  width: 160px;
  min-width: 160px;
  cursor: pointer;

  &.typeB {
    width: 110px;
    min-width: 110px;
    height: 146px;
    display: flex;
    flex-direction: column;

    .FavoriteCardImg {
      max-width: 100px;
      height: 82px;
    }

    .FavoriteCardName {
      height: 12px;
      @include text-truncate-ellipsis(1);
    }

    .FavoriteCardCloseButton {
      top: 3px;
      right: 3px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .FavoriteCardInfoBottom {
      margin-top: 0;
    }

    .FavoriteCardInfo {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .FavoriteCardPrice {
      margin-bottom: 0;
    }

    .FavoriteCardAddButton {
      bottom: 5px;
    }
  }
}

.FavoriteCardCloseButton {
  position: absolute;
  top: 2px;
  right: 2px;
  background: var(--color-background-light-mode);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}

.FavoriteCardImg {
  height: 123px;
  position: relative;
  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
}

.FavoriteCardInfo {
  padding-top: 6px;
}

.FavoriteCardName {
  color: var(--color-inverse-85);
  margin: 0;
  height: 24px;
  line-height: 12px;
  @include text-truncate-ellipsis();
}

.FavoriteCardInfoBottom {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FavoriteCardPrice {
  margin-bottom: 3px;
  display: flex;
  > * + * {
    margin-left: 5px;
  }
}

.FavoriteCardAddButton {
  border: none;
  cursor: pointer;
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: var(--color-mini-button);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
    height: 10px;
  }
}
