.GuestAuth {
  padding: 16px;
  height: 100%;

  .GuestAuthHeader {
    display: flex;
    align-items: center;
  }
  .GuestAuthLogo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .GuestAuthContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: calc(100% - 44px);
  }

}
