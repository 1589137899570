@import "src/assets/styles/mixins";

$routeTransitionDuration: 100ms;

.AdminSidebar {
  //padding: 0 17px 40px 17px;
  width: 280px;
  min-width: 280px;
  height: 100%;
  position: relative;
  background: var(--color-background-light-mode);
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-mini-button);
  transition: 0.3s ease;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);

  .AdminSidebarProfileUsername {
    width: 166px;
    @include text-truncate-ellipsis();
    text-transform: capitalize;
    transition-duration: 300ms;
    text-wrap: wrap;
    overflow: hidden;
    word-break: break-word;
  }

  h5 {
    transition-duration: 300ms;
    white-space: nowrap;
  }

  &.isDesktopBarClosed {
    width: 88px;
    min-width: 88px;

    .RouteName,
    .LogoutButtonText,
    .LockScreenButtonText,
    .AdminSidebarProfileUsername {
      width: 0;
      overflow: hidden;
    }

    .AdminSidebarProfileImageContainer {
      margin-right: 0;
    }

    .AdminSidebarLanguageDropdown {
      display: none;
    }

    .AdminSidebarProfileDetails {
      display: none;
    }

    .AdminSidebarProfile {
      padding-right: 8px;
    }

    .AdminSidebarRouteIcon,
    .AdminSidebarExitIcon {
      margin-right: 0;
    }

    .AdminSidebarLockScreenLogOut {
      flex-direction: column-reverse;
      gap: 4px;
    }

    .AdminSidebarLockScreen,
    .AdminSidebarLogOut {
      width: 100%;
    }
  }

  &.isMobile {
    position: fixed;
    width: 85%;
    left: 0;
    z-index: 5;
    transition: 0.4s;
    overflow-y: auto;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    .AdminSidebarToggleIcon {
      transform: rotateZ(0deg);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.isMobileBarClosed {
    left: -100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.AdminSidebarLogoLanguageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 24px;
  min-height: 46px;
  padding: 0 17px;

  .DropdownSelectedItem {
    border: none;
  }
}

.AdminSidebarProfile {
  border-radius: 10px;
  margin: 8px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
}

.AdminSidebarProfileInfo {
  display: flex;
  align-items: center;
}

.AdminSidebarProfileImageContainer {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  cursor: pointer;
  &:hover {
    .NetworkStatusText {
      display: block;
    }
  }
}
.NetworkStatus {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  content: "";
  border-radius: 50%;
  &.isOnline {
    background-color: var(--color-success);
  }
}
.NetworkStatusText {
  position: absolute;
  padding: 8px;
  top: 11px;
  left: 30px;
  background-color: var(--color-background-light-mode);
  text-wrap: nowrap;
  border-radius: 4px;
  display: none;
  z-index: 5;
}

.AdminSidebarProfileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: grayscale(1);
}

.AdminSidebarProfileDetails {
  background-color: transparent;
}

.AdminSidebarToggle {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  top: 35px;
  right: -16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mini-button);
  border: 1px solid var(--color-stroke);
}

.AdminSidebarToggleIcon {
  transition: 0.3s;
  margin-left: -1px;
  transform: rotateZ(180deg);

  &.isClosed {
    transform: rotateZ(0deg);
  }
}

.AdminSidebarRoutesNavigations {
  overflow-y: auto;
  padding: 0 17px 30px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.AdminSidebarRoute {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  overflow: hidden;
  transition-duration: 30ms;
  margin-bottom: 4px;

  &:hover {
    background-color: var(--color-mini-button);
  }

  &.isActive {
    background-color: var(--color-mini-button);
    position: relative;
    .RouteName {
      font-weight: 600;
    }

    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 100%;
      background-color: var(--color-admin-primary);
      right: 0;
      top: 0;
    }
  }
}

.AdminSidebarRouteIcon {
  margin-right: 16px;
}

.AdminSidebarExitIcon {
  margin-right: 8px;
  min-width: 24px;
  height: 24px;
}

.AdminSidebarRoutes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.AdminSidebarSubRoutes {
  height: 0;
  overflow: hidden;
  padding-left: 23px;

  &.isVisible {
    padding-left: 54px;
    margin-top: 20px;
    height: auto;
    overflow: visible;
  }

  .AdminSidebarSubRoute {
    padding-bottom: 20px;
    position: relative;
    color: var(--color-inverse-25);
    padding-left: 20px;
    cursor: pointer;
    transition: color $routeTransitionDuration,
      font-weight $routeTransitionDuration, font-size $routeTransitionDuration;
    &.isActive {
      color: var(--color-text-black);
      font-weight: 600;
      font-size: 16px;
      transition: color $routeTransitionDuration,
        font-weight $routeTransitionDuration, font-size $routeTransitionDuration;
      &:before {
        background-color: var(--color-admin-primary);
        scale: 1.5;
        top: 30%;
        transition: color $routeTransitionDuration,
          font-weight $routeTransitionDuration,
          font-size $routeTransitionDuration;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      top: 25%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: var(--color-inverse-25);
      border-radius: 50%;
      content: "";
      transition: background-color$routeTransitionDuration,
        transform$routeTransitionDuration, top$routeTransitionDuration;
    }
  }
}

.AdminSidebarLockScreenLogOut {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 17px 30px;
  margin-top: 30px;

  > div {
    width: 120px;
    flex-grow: 1;
    background-color: var(--color-mini-button);
    justify-content: center;
  }
}

.AdminSidebarLockScreen {
  h5 {
    color: var(--color-text-black);
  }

  &:hover {
    background-color: var(--color-group-background-light-mode);
  }
}

.AdminSidebarLogOut {
  h5 {
    color: var(--color-error);
  }

  &:hover {
    background-color: var(--color-error);

    h5 {
      color: var(--color-text-white);
    }

    .AdminSidebarExitIcon {
      path {
        fill: var(--color-text-white);
      }
    }
  }
}

@media (max-width: 1000px) and (min-width: 600px) {
  .AdminSidebar {
    &.isMobile {
      width: 50%;
    }
  }
}
