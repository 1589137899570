.DashboardHeroSection {
  background-color: var(--color-text-black);
  padding: 26px 16px 16px 16px;
  border-radius: 0 0 20px 20px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;

    .DashboardHeroSectionZoneTableName {
      display: flex;
      align-items: center;
      width: calc(100% - 64px);

      h3 {
        color: var(--color-text-white);
      }

      h4 {
        color: var(--color-text-white);
        max-width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        position: relative;

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }

  .DashboardHeroButtons {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    justify-content: space-between;

    .MascotDashboardButton {
      padding: 4px 0;
    }

    >* {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 8px;
      align-items: center;
      width: calc(33.333% - 8px);

      .MascotDashboardButtonInfo {
        margin-left: 0;
        text-align: center;
      }

      .MascotDashboardButton {
        display: flex;
        padding: 4px 0;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        width: 100%;
      }

      .MascotDashboardButtonIcon {
        background: none;

        svg {
          max-width: 40px;
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .DashBoardChatButton {
    position: relative;

    .MascotDashboardButton {
      height: 100%;
    }

    .ChatButtonCount {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: -8px;
      right: -8px;
      background: var(--color-gradient-main);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      h6 {
        color: var(--color-text-black);
      }
    }
  }

  .CustomDashboardButton:last-child {
    svg {
      path {
        stroke: none;
      }
    }
  }

  .ButtonsContainer {
    display: flex;

    >div {
      width: 50%;
    }

    >div+div {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
