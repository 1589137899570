@import "src/assets/styles/_variables";

@mixin activeStyles {
  &:active {
    background: var(--color-stroke);

    svg path {
      stroke: var(--color-text-black);
    }
  }
}

.AddIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;

  &.typeA {
    width: 44px;
    height: 44px;
    background: var(--color-preferred-secondary-bg);
    border-radius: var(--radius-preferred-border-radiusG);

    img {
      border-radius: var(--radius-preferred-border-radiusG);
    }

    svg path {
      stroke: var(--color-preferred-secondary-text);
    }

    .AddIconButtonBadge {
      width: 18px;
      height: 18px;
      top: -7px;
      left: -5px;
      font-size: 10px;
    }

    @include activeStyles;
  }

  &.typeB {
    width: 30px;
    height: 30px;
    background: var(--color-preferred-secondary-bg);
    border-radius: var(--radius-preferred-border-radius);
    //border-radius: 6px;

    img {
      width: 15px;
      height: 15px;
    }

    svg path {
      stroke: var(--color-preferred-secondary-text);
    }

    .AddIconButtonBadge {
      width: 14px;
      height: 14px;
      top: -4px;
      left: -6px;
      font-size: 8px;
    }

    @include activeStyles;
  }

  &.typeC {
    width: 32px;
    height: 32px;
    border-radius: var(--radius-preferred-border-radiusM);
    background: var(--color-preferred-secondary-bg);

    img {
      border-radius: var(--radius-preferred-border-radiusM);
    }

    svg path {
      stroke: var(--color-preferred-secondary-text);
    }

    .AddIconButtonBadge {
      width: 18px;
      height: 18px;
      top: -9px;
      left: -9px;
      font-size: 10px;
    }

    &.menuListViewBorder {
      border-radius: var(--radius-preferred-border-radiusS) var(--radius-preferred-border-radiusT);
      border: 1px solid var(--color-preferred-secondary-bg);
      margin: var(--margin-preferred-marginMR);
    }
  }
}

.AddIconButtonBadge {
  position: absolute;
  background-color: var(--color-background-black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-white);
  font-weight: 500;
  letter-spacing: -0.3px;
}
