.BusinessesLanguageModalBody {
  min-height: fit-content;
}

.BusinessesLanguageModalOption {
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  padding: 16px 0;
  position: relative;
  cursor: pointer;

  .BusinessesLanguageDetails {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .BusinessesLanguageModalOptionName {
    margin-bottom: 3px;
  }

  &:not(:last-child) {
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--color-inverse-25);
      opacity: 0.2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
