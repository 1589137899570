@import "src/assets/styles/_variables";
@import "src/assets/styles/mixins";

$chatFooterHeight: 82px;

.Chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-admin-primary);
  transition: $defaultTransition;
  background-size: cover;
  z-index: 2;
  position: relative;
  .EmptyStateDescription {
    color: var(--color-text-black);
    font-size: 22px;
    font-weight: 500;
  }
}

.ChatHeader {
  padding: 16px;
  border-bottom: 1px solid var(--Neutral-Line, #ededed);
  background: var(--Neutral-White, #fff);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ChatHeaderHamburgerButton {
    border-radius: 8px;
    background: var(--Light-Mode-Mini-Button, #f7f7f7);
  }

  .BackButton {
    svg {
      path {
        fill: var(--color-light-mode-black);
      }
    }
  }
}

.ChatHeaderAvatarImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
}

.ChatHeaderTitle {
  color: var(--Light-Mode-Text-Black, #1f1f1f);
  text-align: center;
}

.ChatHeaderAccordion {
  cursor: pointer;
  border-radius: 8px;
  background: var(--color-mini-button-reverse);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChatBody {
  padding: 22px 20px 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - $chatFooterHeight);

  margin-top: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ChatScrollToBottom {
  position: absolute;
  right: 0;
  bottom: 100px;
  padding: 8px 18px;
  border-radius: 8px 0 0 8px;
  background-color: var(--color-mini-button);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-height: 60px;
  justify-content: center;
}

.ChatUnReadMessageCount {
  margin-top: 4px;
}

.ChatBodyMessageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-left: 4px;

  &:not(:first-child) {
    margin-top: 48px;
  }

  &.isOwnMessage {
    margin-top: 4px;
    justify-content: flex-end;

    .ChatBodyMessage {
      border-radius: 8px;
      background: var(
        --Light-Mode-Text-Black,
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #1f1f1f
      );
      h4 {
        color: var(--color-text-white);

      }
      .ChatBodyMessageTime{
        color: var(--color-light-gray);

      }
    }
  }

  &.isSameAuthor {
    margin: 4px;
  }
}

.ChatBodyMessage {
  padding: 12px 50px 12px 10px;
  border-radius: 8px;
  text-align: left;
  background-color: var(--Light-Mode-Mini-Button, #f7f7f7);
  width: fit-content;
  display: flex;
  max-width: 300px;
  position: relative;
  line-break: anywhere;

  & > h4 {
    color: var(--color-inverse-100);

  }
}

.ChatBodyMessageAuthor {
  position: absolute;
  top: -28px;
  left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90vw;

  .ChatBodyMessageAuthorName {
    margin-bottom: 7px;
    font-weight: 500;
    color: var(--color-text-black);

    @include text-truncate-ellipsis(1);
    width: max-content;

    span {
      color: var(--color-inverse-85);
    }
  }
}

.ChatBodyMessageTime {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: var(--color-inverse-75);

}

.ChatFooter {
  width: 100%;
  padding: 8px 16px 16px 16px;
}

.ChatFooterForm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  height: 56px;
  background: rgba(247, 247, 247, 0.5);
  backdrop-filter: blur(10px);

  .InputControlContainer {
    flex: 1;
  }
}

.ChatFooterFormInput {
  .InputControlInput {
    background-color: transparent;
  }
  .InputControlLabel{
    color: var(--color-inverse-85);
  }
}

.ChatFooterFormSubmitButton {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(
    --Light-Mode-Gradient-Main,
    linear-gradient(90deg, #fd0 0%, #fbb034 100%)
  );
  margin: 5px 5px 5px 0;
}
