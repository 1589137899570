@import "src/assets/styles/variables";

.ModalFooterContainer {
  display: flex;
  width: 100%;

  > button {
    &:first-child {
      margin-right: 10px;
    }
  }
}
