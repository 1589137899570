@import "src/assets/styles/_variables";
.TextAreaWrapper {
  height: 136px;
  .TextAreaContainer {
    height: 111px;
    border-radius: 8px;
    overflow: hidden;
  }
  .TextAreaControl {
    width: 100%;
    height: 111px;
    padding: 12px;
    background: none;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    resize: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    color: white;
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
    }

    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px var(--color-background-light-mode) inset;
    }
    &.noFloating {
      &::placeholder {
        color: var(--color-inverse-50);
        line-height: 20px;
        font-size: 14px;
      }
    }
    &:focus {
      border: 1px solid var(--color-admin-primary);
    }
  }

  .TextArea::-webkit-scrollbar {
    display: none;
  }

  p {
    color: white;
    text-align: end;
    padding: 8px 0 0 0;
  }
}
