.TableV2 {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 20px 0;

  .HeaderRow {
    width: 100%;
    text-align: left;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    border-bottom: 1px solid rgba(128, 128, 128, 0.192);
    background: rgb(245, 244, 244);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 60px;
    padding: 10px 15px;
    gap: 16px;
  }

  .BodyWrapper {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;

    .SpinnerContainer {
      height: 25vh;
    }

    .BodyRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      transition: background-color 0.15s;
      padding: 10px 15px;
      border-bottom: 1px solid var(--Light-Mode-Stroke, #efeded);

      &:hover {
        background-color: rgb(247, 247, 247);
      }
    }


    .Footer {
      padding: 20px;
    }
  }
}

@mixin table-cell-style($width, $display) {
  flex-basis: if($display != "none", $width + "%", 0);
  display: $display;
}
