@import 'src/assets/styles/_variables';

.GuestInformation {
  background: var(--color-text-black);
  border-radius: 12px;
  //padding: 5px 5px 5px 20px;
  padding: 8px;
  display: flex;
  align-items: center;

  & > div {
    flex-grow: 1;
  }

  h2 {
    color: var(--color-text-white);
  }

  p {
    color: var(--color-inverse-25);
    margin-bottom: 7px;
  }

  .GuestInformationCountWrapper {
    position: relative;
    padding-right: 10px;
    //border-right: 1px solid var(--color-inverse-25);
    display: flex;
    align-items: center;
    gap: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .GuestInformationHeader {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h6 {
        color: var(--color-inverse-25);
        width: max-content;
      }
    }
    .GuestInformationResult {
      display: flex;
      flex-direction: column;
      gap: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      h5 {
        color: var(--color-text-white);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
      }
    }
  }

  .GuestInformationPriceWrapper {
    //margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      padding: 8px 32px;
      gap: 5px;
    }
    .GuestPayDetail {
      display: flex;
      align-items: center;
      gap: 4px;
      h6 {
        padding-top: 2px;
        text-wrap: nowrap;
      }
    }
  }

  &.typeB {
    height: 86px;

    .GuestInformationCountWrapper {
      flex-grow: unset;
    }

    .GuestInformationPriceWrapper {
      flex-grow: 2;
    }
  }
  &.typeC {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: transparent;
    .Price {
      margin-left: auto;
    }
    .GuestPayDetail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .GuestInformationPayButtonWrapper {
      &:not(:disabled){
        background-color: var(--color-preferred-secondary-bg);
        &:active{
          scale: 0.95;
        }
        .GuestPayDetail{
          h3,h6{
            color: var(--color-preferred-secondary-text);
          }
        }
      }
    }

  }
}
