@import "src/assets/styles/variables";

.Stepper {
  display: flex;
  margin-top: 56px;
  flex-direction: column;
  @media (max-width: $mobile) {
    margin-top: 42px;
  }
}
